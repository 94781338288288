const Eth = "../../../../assets/icon/ethereum.svg";
const BNB = "../../../../assets/icon/binance.svg";
const POLYGON = "../../../../assets/icon/polygon.svg";

export const chainsImg = {
    5: {
        icon: Eth,
        // name: "Goreli",
        // symbol: "ETH",
        // chain: 5,
        // disabled: false,
        // ticker: "ethereum"
    },
    97: {
        icon: BNB,
    },
    1: {
        icon: Eth,
    },
    56: {
        icon: BNB,
    },
    80001: {
        icon: POLYGON,
    },
};
