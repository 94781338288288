import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaTimes } from "react-icons/fa";
import { chainsImg } from "./chaindata";
// import { toHex } from "../../web3/helpers/index";
import { useWeb3React } from "@web3-react/core";
// import { RPC_URLS, POLLING_INTERVAL } from "../../web3/wallet/connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useNetwork, useSwitchNetwork } from "wagmi";
// import { WalletModalContext } from "../../context/walletModalContext";
// import { ThemeContext } from "../../context/themeContext";

export default function ChainModal({ open, handleClose }) {
    const { account, library, chainId, deactivate, activate } = useWeb3React();
    const { chain } = useNetwork()
    const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

    //   const { connectHandler } = React.useContext(WalletModalContext);
    // const { theme } = React.useContext(ThemeContext);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        bgcolor: "#111721",
        //   border: "2px solid #000",
        boxShadow: 24,
        p: 2,
        color: "#fff",
        borderRadius: 2,
    };


    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                classes={""}
            >
                <Box sx={style}>
                    <div >
                        <h2 style={{ marginLeft: "25%", color: "white", fontWeight: "bold" }}>Change Chain</h2>
                        <button style={{ position: "absolute", top: "10px", right: "10px" }} onClick={handleClose}>
                            <FaTimes />
                        </button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {chains.map((x) => (
                            <button
                                disabled={!switchNetwork || x.id === chain?.id}
                                key={x.id}
                                onClick={() => switchNetwork?.(x.id)}
                                style={{ margin: "5px 0 5px 0", display: "flex" }}
                            >
                                <img src={chainsImg[x?.id]?.icon} alt={x?.name} width={"35px"} height={"50px"} style={{ height: "50px" }} />
                                <span style={{ marginLeft: "30px", marginTop: "12px" }}>
                                    {x.name}
                                    <span className="text-sm">{isLoading && pendingChainId === x.id && ' (switching)'}</span>
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className="text-red-600 text-sm"> {error && error.message}</div>
                </Box>
            </Modal>
        </div>
    );
}
