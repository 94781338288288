import { HiMenuAlt3 } from "react-icons/hi";
import { FaWallet } from "react-icons/fa";

import logo from "../../../../assets/images/global/logo.svg";
import top_right from "../../../../assets/images/header/top_right.svg";
import banner from "../../../../assets/images/header/banner.svg";

import { useLayoutContext } from "../../../../contexts/LayoutContext";
import { useState } from "react";
import { shortAddress } from "../../../../web3/helpers";
import WalletConnection from "./Modal";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import ChainModal from "./ChainModal";


const styles = {
  button:
    "flex items-center bg-transparent py-1 px-2 xs:py-2 xs:px-4 md:py-2 md:px-6 uppercase text-xs font-bold",
};

const NavBar = () => {
  const { setMobileOpen } = useLayoutContext();
  const [open, setOpen] = useState(false);
  const [openChain, setOpenChain] = useState(false);
  const { isConnected, address } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()


  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleChainModalOpen = () => {
    setOpenChain(true);
  };

  const handleChainModalClose = () => {
    setOpenChain(false);
  };


  return (
    <>
      <div className="relative">

        <WalletConnection open={open} handleModalClose={handleModalClose} />
        <ChainModal open={openChain} handleClose={handleChainModalClose} />

        <div
          style={{
            backgroundImage: `url(${top_right})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "300px",
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: "-1",
          }}
          className="w-full h-full"
        />

        <div className="flex justify-between lg:justify-end items-center z-10 px-4 py-5 md:p-5">
          <div className="flex items-center lg:hidden">
            <img src={logo} className="w-9 h-9 xs:w-11 xs:h-11 mr-4" />
          </div>

          <div className="flex justify-end items-center">
            <div className="flex items-center">

              {chain?.name && (
                <div className="flex items-center mr-4">
                  <button
                    type="button"
                    className={`${styles.button} border-2 border-[#393941] text-white`}
                    onClick={handleChainModalOpen}
                  >
                    {chain?.name}
                  </button>
                </div>
              )}

              <div>
                {isConnected ?
                  <button
                    onClick={disconnect}
                    type="button" className={`${styles.button} bg-white`}>
                    <FaWallet className="mr-2" />
                    <span>{shortAddress(address)}</span>
                  </button>
                  :
                  <button
                    onClick={handleModalOpen}
                    type="button" className={`${styles.button} bg-white`}>
                    <FaWallet className="mr-2" />
                    <span className="hidden md:block">CONNECT</span>
                  </button>
                }
              </div>
            </div>

            <div className="flex lg:hidden items-center">
              <HiMenuAlt3 className="text-white text-2xl ml-4 cursor-pointer"
                onClick={() => setMobileOpen(true)}
              />
            </div>
          </div>

        </div>
      </div>

      <div>
        <img src={banner} className="w-full" alt="banner" />
      </div>
    </>
  );
};

export default NavBar;
