import logo from "../../assets/images/global/logo.svg"
import { IoIosCopy } from "react-icons/io"
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { formatEther } from "ethers/lib/utils";
import { useAccount } from "wagmi";

const ReferralLink = ({ referralBonus, userReferralIncome, chainName, contractAddress }) => {
    const { address } = useAccount()
    return (
        <div className="flex justify-center">
            <div className='bg-[#3A3B49] py-2 px-5 lg:py-0'>
                <div className="flex flex-col justify-center items-center w-full pb-5">
                    <img src={logo} alt="logo" className='w-12 h-12 lg:w-16 md:h-16' />

                    <h2 className='text-xs tracking-widest uppercase'>
                        Your Referral Link
                    </h2>

                    <div className="bg-[#1F1F1F] p-1 md:px-4 mt-4 rounded flex items-center justify-center">
                        <span className="text-[6px] xs:text-[9px] xl:text-xs text-gray-100 tracking-wider p-2 flex-grow text-center">
                            {`https://${window.location.hostname}/details/${contractAddress}/${chainName}?ref=${address}`}
                        </span>
                        <CopyToClipboard
                            onCopy={(e) => toast.success("Copied to clipboard")}
                            text={`https://${window.location.hostname}/details/${contractAddress}/${chainName}?ref=${address}`}
                        >
                            <IoIosCopy className='text-xl ml-2 cursor-pointer' />
                        </CopyToClipboard>

                    </div>

                    <span className='text-xs tracking-widest uppercase mt-5'>
                        COMISSIONED EARNED
                    </span>

                    <div className="bg-[#1F1F1F] py-1 px-24 mt-2 rounded flex items-center justify-center">
                        <span className="text-xs text-gray-100 tracking-wider">
                            {Number(formatEther(userReferralIncome)).toFixed(6)}
                        </span>
                    </div>

                    <span className='text-xs tracking-widest uppercase mt-5'>
                        REFERRAL BONUS
                    </span>

                    <div className="bg-[#1F1F1F] py-1 px-24 mt-2 rounded flex items-center justify-center">
                        <span className="text-xs text-gray-100 tracking-wider">
                            {referralBonus}%
                        </span>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ReferralLink