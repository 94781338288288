import { useNavigate } from "react-router-dom";
import container_img from "../../assets/images/home/stake_container.svg"
import web_icon from "../../assets/images/home/web_icon.svg";
import telegram_icon from "../../assets/images/home/telegram_icon.svg";
import discord_icon from "../../assets/images/home/discord_icon.svg";
import { IERC20, chainTicker, coinGeckoTicker, getChainName, presaleAbi, rpc } from "../../config";
import Web3 from "web3";
import { formatEther } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";
import { chainsImg } from "../common/Header/NavBar/chaindata";
import { routerAbi } from "../../constants/routerAbi";
import { factoryAbi } from "../../constants/factoryAbi";
import { formatUnits } from "viem";

const styles = {
  tag: 'text-center py-1 px-4 text-sm uppercase rounded-sm',
  gridDiv: 'col-span-4 flex items-center justify-center text-xs tracking-wider',
}

const StakeContainer = ({ farmDetails, chainId, }) => {
  const { address } = useAccount()
  const navigate = useNavigate();

  const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chainId]));
  const contractR = new web3.eth.Contract(presaleAbi, farmDetails);
  const [data, setData] = useState();
  const [dollarPrice, setDollarPrice] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [cancelled, setCancelled] = useState(false);
  const [duration, setDuration] = useState(0);
  const [finalized, setFinalized] = useState(false);
  const [shifted, setShifted] = useState(false);
  const [userBnbBalance, setUserBnbBalance] = useState(0);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [perTokenPriceBnb, setPerTokenPriceBnb] = useState(0);
  const [tokenTotalSupply, setTokenTotalSupply] = useState(0);


  useEffect(() => {
    abc();
  }, []);

  const abc = async () => {
    const _data = await contractR.methods.getSelfInfo().call();

    setData(_data);

    const _can = await contractR.methods.poolCancelled().call();

    setCancelled(_can);

    const _fin = await contractR.methods.poolFinalized().call();

    setFinalized(_fin);

    const _shif = await contractR.methods.poolShifted().call();
    setShifted(_shif);

    const _dur = await contractR.methods.poolDuration().call();

    setDuration(Number(_dur) / (60 * 60 * 24));


    const TokencontractR = new web3.eth.Contract(IERC20, _data[3]);

    const tokenDecimals = await TokencontractR.methods.decimals().call();
    const _totalSupply = await TokencontractR.methods.totalSupply().call();
    setTokenTotalSupply(+formatUnits(
      _totalSupply.toString(),
      tokenDecimals.toString()
    ))

    const routerR = new web3.eth.Contract(routerAbi, _data[18]);
    const factoryAddress = await routerR.methods.factory().call();
    const WethAddress = await routerR.methods.WETH().call();
    const wethContract = new web3.eth.Contract(IERC20, WethAddress);

    // console.log("factoryAddress", factoryAddress, WethAddress)
    const factoryR = new web3.eth.Contract(factoryAbi, factoryAddress);
    const pairAddress = await factoryR.methods.getPair(_data[3], WethAddress).call();
    // const pairContract = new web3.eth.Contract(IERC20, pairAddress);

    const reserve0 = await TokencontractR.methods.balanceOf(pairAddress).call();
    const reserve1 = await wethContract.methods.balanceOf(pairAddress).call();

    // const totalSupply = await pairContract.methods.totalSupply().call();

    // setLpValueInBnb(+reserve1 / +totalSupply)

    // const _totalTokensLocked =  await contractR.methods.owner().call();
    // const _totalLpLocked = await contractR.methods.owner().call();



    const pariTokenBlance = +formatUnits(
      reserve0.toString(),
      tokenDecimals.toString()
    );
    const pairBnbBlance = +formatEther(reserve1.toString());

    const price = pairBnbBlance / pariTokenBlance;

    setPerTokenPriceBnb(price);

  };

  useEffect(() => {
    if (address) {
      getUserBalance();
    }
  }, [address]);

  const getUserBalance = async () => {
    const _uBal = await contractR.methods
      .userPresaleMBalance(address)
      .call();

    setUserBnbBalance(+formatEther(_uBal));

    const _uTBal = await contractR.methods
      .userPresaleTBalance(address)
      .call();

    setUserTokenBalance(+formatEther(_uTBal));
  }

  useEffect(() => {
    getPrice();
  }, []);

  const getPrice = async () => {
    try {
      const ethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coinGeckoTicker[chainId]}&vs_currencies=usd`
      );

      setDollarPrice(ethPrice.data[coinGeckoTicker[chainId]].usd);
    } catch (error) {
      console.log("price error", error);
    }
  }

  const status = (data && finalized) ? shifted? "SHIFTED" : "COMPLETED"
    : cancelled ? "CANCELLED" :
      "LIVE";

  const progress = data
    ? (Number(formatEther(data[5])) / Number(formatEther(data[0]))) * 100
    : 0;

  const socials = data && data[20]?.split("=")
  const website = data && socials[0]
  const telegram = data && socials[1]
  const discord = data && socials[2]

  console.log("data", status);


  const videoA = data && data[16].includes("youtube.com")

  const banner = data && (data[16]?.includes(".jpg") || data[16]?.includes(".png") ||
    data[16]?.includes(".jpeg") || data[16]?.includes(".gif"))
    ? data[16] : container_img

  const youtubeArray = data && data[16].split("watch?v=");
  const youtubeVideo =
    data && `https://www.youtube.com/embed/${youtubeArray[1]}`;

  return (
    <div className="flex flex-col justify-center bg-container text-white shadow-md mt-8 xl:mt-0">
      <div className="flex p-4 justify-between items-center">
        <div className="flex items-center">

          <img src={data && data[12]} alt="logo" className="w-14 h-14" />
        </div>

        <div className="flex items-center">
          <div className={`${styles.tag} ${data && data[10][0] ? 'bg-[#81B441]' : 'bg-[#4E4E5A]'}  `}>
            KYC
          </div>

          <div className={`${styles.tag} ${data && data[10][1] ? 'bg-[#81B441]' : 'bg-[#4E4E5A]'} mx-2`}>
            Audit
          </div>

          <div className={`${styles.tag} ${data && data[10][2] ? 'bg-[#81B441]' : 'bg-[#4E4E5A]'}`}>
            Safo
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center bg-[#222231]">
        <span className="text-lg uppercase text-[#A3FF12]">{data && status}</span>
      </div>

      <div>
        {data && data[16].includes("youtube") ? (
          <iframe
            width="100%"
            height="372px"
            style={styles}
            //                src={"https://www.youtube.com/embed/eI4an8aSsgw"}
            src={data && youtubeVideo}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <img
            width="100%"
            height="372px"
            style={styles}
            //                src={"https://www.youtube.com/embed/eI4an8aSsgw"}
            src={banner}
          ></img>
        )}
        {/* <img 
             
            srcSet={data && banner}
//            src={container_img} 
            alt="container" 
            className="w-full" 
            /> */}
        {/* <img ref={imgRef} src={data && data[16]} onError={onImageError}  className="w-full" ></img> */}
      </div>

      <div className="p-2">
        <h1 className="text-lg tracking-wide">{data && data[13]}</h1>

        <span className="text-xs text-gray-500">
          1 {chainTicker[chainId]} price = {data && formatEther(data[2])}{" "}
          {data && data[15]}
        </span>
      </div>

      <div className="flex justify-center items-center py-4 px-12">
        <div className="w-full text-center bg-[#484959] py-2 px-4 rounded text-xs tracking-wide">
          SOFT CAP = {data && formatEther(data[0])} {chainTicker[chainId]} ={" "}
          {data && Number(Number(formatEther(data[0])) * dollarPrice).toFixed(2)}{" "}
          $
        </div>
      </div>

      <div className="px-4">
        <div className="w-full bg-[#484959] rounded">
          <div style={{ width: `${progress <= 100 ? progress : 100}%` }} className="bg-[#9DFF94] text-xs font-medium text-black text-right p-0.5 leading-none rounded w-[45%]">{progress <= 100 ? progress.toFixed(0) : 100}%</div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 mt-4 py-1 bg-[#484959]">
        <div className={`${styles.gridDiv}`}>
          Farm Duration
        </div>

        <div className={`${styles.gridDiv}`}>
          Liquidity
        </div>

        <div className={`${styles.gridDiv}`}>
          Total Raised
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 pt-3">
        <div className={`${styles.gridDiv}`}>
          {duration} Days
        </div>

        <div className={`${styles.gridDiv}`}>
          {data && data[11]} {"%"}
        </div>

        <div className={`${styles.gridDiv}`}>
          {data && Number(formatEther(data[5])).toFixed(4)}{" "}
          {chainTicker[chainId]}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 pt-2">
        <div className="flex items-center col-span-9">
          <div className="py-1 pl-7 bg-[#484959] text-xs tracking-wide w-full rounded">
            Fully Diluted MC Estimate:{" "}
            {data &&
              Number(tokenTotalSupply * (perTokenPriceBnb * dollarPrice)).toFixed(2)} $
          </div>
        </div>

        <div className="flex items-center col-span-3">
          <img src={chainsImg[chainId].icon} alt="bnb" className="w-8 h-8 ml-2" />
        </div>
      </div>

      {/* <div className="flex justify-center items-center py-1 bg-[#484959] text-xs tracking-wide mt-4">
            Time Left: 07:11:58:09
        </div> */}


      {address &&
        <div className="flex justify-center items-center py-1 bg-[#484959] text-xs tracking-wide mt-4">
          My Contributions :{" "}
          {data &&
            (Number(userBnbBalance))}{" "}
          {chainTicker[chainId]} - {Number(userTokenBalance).toFixed(3)} {data && data[15]}
        </div>
      }


      <div className="flex justify-center items-center mt-4">
        <button type="button" className="bg-[#26273A] tracking-wide text-white text-xs px-10 py-2 rounded"
          onClick={() => {
            navigate(`/details/${farmDetails}/${getChainName[chainId]}`);
          }}

        >
          View
        </button>

      </div>


      <div className="flex justify-center items-center py-2 bg-[#222231] mt-8">
        <a
          href={website}
          target="_blank"
        >
          <img src={web_icon} alt="web_icon" className="w-6 h-6 cursor-pointer" />
        </a>

        <a
          href={telegram}
          target="_blank"
        >
          <img src={telegram_icon} alt="telegram_icon" className="w-6 h-6 mx-4 cursor-pointer" />
        </a>

        <a
          href={discord}
          target="_blank"
        >
          <img src={discord_icon} alt="discord_icon" className="w-6 h-6 cursor-pointer" />
        </a>



      </div>

    </div>
  );
};

export default StakeContainer;
