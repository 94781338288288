import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import locker from "../../../../assets/images/header/locker.svg"  //"../images/header/locker.svg"
import headerData from "../../../../assets/data/header";

import { useLayoutContext } from "../../../../contexts/LayoutContext";
import { isMobile } from "react-device-detect";
import { useWeb3React } from "@web3-react/core";
import { LaunchPadABI, chainidSelected, launchPadAdd, rpc } from "../../../../config";
import Web3 from "web3";

const styles = {
  showMenuNav:
    "absolute top-0 left-0 flex flex-col justify-evenly items-center w-full h-screen bg-[#222231] transform translate-y-0 transition duration-500",
  hideMenuNav:
    "absolute top-0 left-0 flex flex-col justify-evenly items-center w-full h-screen bg-[#222231] transform -translate-y-full transition duration-500",
};

const BurgerMenu = () => {
  const { mobileOpen, setMobileOpen, active, setActive } = useLayoutContext();
  const {account,chainId} = useWeb3React()
  const chain = chainId ? chainId : chainidSelected;
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain]))
  const contractR = new web3.eth.Contract(LaunchPadABI,launchPadAdd[chain])
  const [admin, setAdmin] = useState();
  const [open, setOpen] = useState(true);
  
  useEffect(()=>{
    const abc = async ()=>{
      const _farm = await contractR.methods.admin().call()
      setAdmin(_farm)
    }

    abc()


   },[]) 


  useEffect(() => {
    if (mobileOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [mobileOpen]);



  return (
    <div className="flex items-center justify-between border-b border-black py-8">
      <nav>
        <div
          className={
            mobileOpen ? `${styles.showMenuNav}` : `${styles.hideMenuNav}`
          }
        >
          <div
            className="absolute top-0 right-0 px-8 py-8"
            onClick={() => setMobileOpen(false)}
          >
            <svg
              className="h-8 w-8 text-white cursor-pointer"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>

          <ul className="flex flex-col items-center justify-between min-h-[250px]">
            {headerData.map((header, index) => (
              <Link
                key={index}
                className={`flex rounded-md p-2 cursor-pointer hover:bg-white/20 text-gray-300 text-sm items-center gap-x-4 ${
                  active === index && "bg-[#2D2F39]"
                } mt-2`}
                to={header.link}
                target={header.link.includes("http") ? "_blank" : "_self"}
                onClick={() => setActive(index)}
              >
                <img src={header.icon} />
                <span
                  className={`${
                    !isMobile && "hidden"
                  } origin-left duration-800`}
                >
                  {header.title}
                </span>
              </Link>
            ))}

            {account && admin == account ? (
              <Link
                className={`flex rounded-md p-2 cursor-pointer hover:bg-white/20 text-gray-300 text-sm items-center gap-x-4 ${
                  active === headerData.length + 1 && "bg-[#2D2F39]"
                } mt-2`}
                to={"/createpresale"}
                onClick={() => 
                  {setMobileOpen(false)
                    setActive(headerData.length + 1)
                  }
                  }
              >
                <img src={locker} />
                <span
                  className={`${!open && "hidden"} origin-left duration-800`}
                >
                  Create Presale
                </span>
              </Link>
            ) : null}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default BurgerMenu;
