
const styles = {
    text: 'text-xs tracking-wide',
}

const TokenDetails = ({symbol,liquidity,softCap,marketCap,price,tsymbol}) => {
  return (
    <div className='grid grid-cols-12'>
        <div className='col-span-6 lg:col-span-5'>
            <div className='flex flex-col justify-center items-start space-y-2'>
                <span className={`${styles.text}`}>Network</span>
                <span className={`${styles.text}`}>Liquidity</span>
                <span className={`${styles.text}`}>Soft Cap</span>
                <span className={`${styles.text}`}>Fully Diluted MC Estimate</span>
                <span className={`${styles.text}`}>Tokens per {symbol}</span>
            </div>
        </div>

        <div className='col-span-6 lg:col-span-7 flex justify-end lg:justify-start items-center'>
            <div className='flex flex-col justify-center space-y-2'>
                <span className={`${styles.text}`}> {symbol}</span>
                <span className={`${styles.text}`}>{liquidity &&  liquidity} %</span>
                <span className={`${styles.text}`}>{softCap && softCap} {symbol}</span>
                <span className={`${styles.text}`}>${marketCap && marketCap}</span>
                <span className={`${styles.text}`}>{price && price}</span>
            </div>
        </div>
    </div>
  )
}

export default TokenDetails