import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { IERC20, LaunchPadABI, RouterA, launchPadAdd, rpc } from "../../config";
import { toast } from "react-hot-toast";
import { parseEther } from "ethers/lib/utils";
import ResponsiveDialog from "../../spinner";
import { useAccount, useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { useLayoutContext } from "../../contexts/LayoutContext";

const styles = {
  padding: "p-2 pt-8 xl:px-44 2xl:px-50 text-white",
  input:
    "block w-full px-4 py-2 mt-2 border rounded-md bg-[#1F1F1F] font-sans text-sm text-white border-gray-600 focus:border-gray-900 focus:outline-none",
};

const { padding, input } = styles;


const CreatePresale = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { mainnetChains, testnetChains } = useLayoutContext();

  const [name, setName] = useState("abb");
  const [logo, setLogo] = useState("abc");
  const [softcap, setSoftCap] = useState("0.001");
  const [price, setPrice] = useState("0.0001");
  const [token, setToken] = useState("0xf1fC2fC546347f6f76e9044fC379b5A3046d0c9d");
  // const [endTime, setEndTime] = useState(1694725669);
  // const [startTime, setStartTime] = useState(1614725669);
  const [min, setMin] = useState("0.0001");
  const [max, setMax] = useState("0.001");

  const [Liquidity, setLiquidity] = useState(60);
  const [duration, setDuration] = useState(60);
  const [youTube, setYouTube] = useState("aaa");
  const [description, setDescription] = useState("aaa");
  const [openSpinner, setOpenspinner] = useState(false);
  const [website, setWebsite] = useState("https://www.");
  const [telegram, setTelegram] = useState("https://www.");
  const [discord, setDiscord] = useState("https://www.");

  const [symbol, setSymbol] = useState("abc");
  const [socials, setSocials] = useState("");

  const navigate = useNavigate();

  const verify = () => {
    if (website.slice(0, 12) != "https://www.") {
      console.log(
        "first",
        website,
        website.slice(0, 13),
        "https://www.",
        website.slice(0, 13) != "https://www."
      );
      toast.error("website not valid");
    } else if (telegram.slice(0, 12) != "https://www.") {
      toast.error("telegram not valid");
    } else if (discord.slice(0, 12) != "https://www.") {
      toast.error("discord not valid");
    } else {
      return true;
    }
  };


  useEffect(() => {
    if (isConnected && token.length === 42) {
      getInfo()
    }
  }, [isConnected, chain?.id, token]);

  useEffect(() => {
    const _socials = `${website}=${telegram}=${discord}`
    setSocials(_socials)
  }, [website, telegram, discord])

  const getInfo = async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain?.id]))
    const _contract = token && new web3.eth.Contract(IERC20, token)
    const _symbol = await _contract.methods.symbol().call()
    setSymbol(_symbol.toString())
  }

  const { write: approveTokenWrite, data: approveTokenData } = useContractWrite(
    {
      address: token.length > 0 && token.length === 42 ? token : "0x55d398326f99059fF775485246999027B3197955",
      abi: IERC20,
      functionName: 'approve',
      args: [launchPadAdd[(isConnected && (mainnetChains.includes(chain?.id) || testnetChains.includes(chain?.id))) ? chain?.id : 56], softcap.length > 0 && price.length > 0 ? parseEther((softcap * price).toString()).toString() : "0"],
      onError: (error) => {
        var str = JSON.stringify(error)
        var obj = JSON.parse(str)
        var reason = obj.cause.reason
        reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
        setOpenspinner(false);

      }
    }
  )

  console.log("address", launchPadAdd[(isConnected && (mainnetChains.includes(chain?.id) || testnetChains.includes(chain?.id))) ? chain?.id : 56])
  const { write: buyTokenWrite, data: buyTokenData } = useContractWrite(
    {
      address: launchPadAdd[(isConnected && (mainnetChains.includes(chain?.id) || testnetChains.includes(chain?.id))) ? chain?.id : 56],
      // address: "0xCC537D6cb691c4dc8eCff84aF133E0e5A01AFecD",
      abi: LaunchPadABI,
      functionName: 'launch',
      args: [
        [token.length > 0 && token.length === 42 ? token : "0x55d398326f99059fF775485246999027B3197955", RouterA[isConnected && (mainnetChains.includes(chain?.id) || testnetChains.includes(chain?.id)) ? chain?.id : 56]],
        [
          softcap.length > 0 && softcap != "." ? parseEther(softcap).toString() : "0", //0
          price.length > 0 && price != "." ? parseEther(price).toString() : "0", //1
          0, //2
          0, //3
          min.length > 0 && min != "." ? parseEther(min).toString() : "0", //4
          max.length > 0 && max != "." ? parseEther(max).toString() : "0", //5
          Liquidity.length > 0 ? Liquidity : "0", //6
          duration.length > 0 ? duration : "0", //7
        ],
        [
          name.length > 0 ? name : "abc",
          logo.length > 0 ? logo : "abc",
          symbol.length > 0 ? symbol : "abc",
          youTube.length > 0 ? youTube : "abc",
          description.length > 0 ? description : "abc",
          socials.length > 0 ? socials : "abc",
        ]
      ],
      onError: (error) => {
        var str = JSON.stringify(error)
        var obj = JSON.parse(str)
        var reason = obj.cause.reason
        reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
        setOpenspinner(false);

      }
    }
  )

  const approveTokenWait = useWaitForTransaction({
    hash: approveTokenData?.hash,
    onSuccess() {
      buyTokenWrite();
    }
  })

  const buyTokenWait = useWaitForTransaction({
    hash: buyTokenData?.hash,
    onSuccess() {
      setOpenspinner(false);
      navigate("/");
    }
  })

  // const condition = token.slice(0, 2) == "0x" && token.length == 42;

  // const initialPresale = async () => {
  //   if (condition) {
  //     if (verify()) {
  //       const contract = token && new web3.eth.Contract(IERC20, token);
  //       console.log("chain", chainId);
  //       const symbol = await contract.methods.symbol().call();
  //       const amount = softcap / price;
  //       writeFunction(
  //         "approval",
  //         "approve",
  //         IERC20,
  //         token,
  //         500000,
  //         [launchPadAdd[chainId], parseEther(amount.toString())],
  //         () => {
  //           const socials = `${website}=${telegram}=${discord}`;
  //           writeFunction(
  //             "create Presale",

  //             "launch",
  //             LaunchPadABI,
  //             launchPadAdd[chainId],
  //             6000000,
  //             [
  //               [token, RouterA[chainId]],
  //               [
  //                 parseEther(softcap), //0
  //                 parseEther(price), //1
  //                 0, //2
  //                 0, //3
  //                 parseEther(min), //4
  //                 parseEther(max), //5
  //                 Liquidity, //6
  //                 duration, //7
  //               ],
  //               [name, logo, symbol, youTube, description, socials],
  //             ],
  //             () => {
  //               setOpenSpinner(false);
  //               navigate("/");
  //             }
  //           );
  //         }
  //       );
  //     }
  //   } else {
  //     toast.error("please enter valid token");
  //   }
  // };

  // const writeFunction = async (
  //   _name,
  //   fun,
  //   _abi,
  //   conAdd,
  //   _gasLimit,
  //   args,
  //   fall
  // ) => {
  //   console.log("data received", _name, fun, _abi, conAdd, _gasLimit, args);
  //   const contract = getContract(library, account, conAdd, _abi);
  //   if (account) {
  //     setOpenSpinner(true);
  //     try {
  //       const tx1 = await contract[fun](...args, { gasLimit: _gasLimit });
  //       const receipt = await tx1.wait();
  //       if (receipt) {
  //         console.log("data", receipt);
  //         fall();
  //         //setOpenSpinner(false);
  //       }
  //     } catch (error) {
  //       console.log(`error in ${_name}`, error);
  //       setOpenSpinner(false);
  //     }
  //   } else {
  //     toast.error("Please connect your wallet");
  //   }
  // };

  return (
    <main className={`${padding}`}>
      <section className="p-6 mx-auto bg-[#1E1F35CC]/80 rounded-md shadow-md mt-20">
        <h1 className="mb-5 text-xl font-bold Upercase">Create Presale</h1>
        <form className="bg-[#3A3B49] p-2">
          <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
            <div>
              <label htmlFor="username">Presale Name</label>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                id="username"
                type="text"
                placeholder="Enter Presale Name"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Logo URL</label>
              <input
                value={logo}
                onChange={(e) => {
                  setLogo(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Logo URL"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="username">Soft Cap</label>
              <input
                value={softcap}
                onChange={(e) => {
                  setSoftCap(e.target.value);
                }}
                id="username"
                type="text"
                placeholder="Enter Soft Cap"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Price Per BNB</label>
              <input
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Price Here"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="username">Token</label>
              <input
                value={token}
                onChange={(e) => {
                  e.preventDefault();
                  setToken(e.target.value);
                }}
                id="username"
                type="text"
                placeholder="Enter Token Here"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Minimum</label>
              <input
                value={min}
                onChange={(e) => {
                  setMin(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Minimum Here"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="username">Maximum</label>
              <input
                value={max}
                onChange={(e) => {
                  setMax(e.target.value);
                }}
                id="username"
                type="text"
                placeholder="Enter Maximum Cap"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Liquidity</label>
              <input
                value={Liquidity}
                onChange={(e) => {
                  setLiquidity(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Liquidity Here"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="username">Pool Duration</label>
              <input
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                id="username"
                type="text"
                placeholder="Enter Pool Duration"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Youtube Link</label>
              <input
                value={youTube}
                onChange={(e) => {
                  setYouTube(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Youtube Link"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="username">Description</label>
              <input
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                id="username"
                type="text"
                placeholder="Enter Description"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Website</label>
              <input
                value={website}
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Website Here"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="username">Telegram</label>
              <input
                value={telegram}
                onChange={(e) => {
                  setTelegram(e.target.value);
                }}
                id="username"
                type="text"
                placeholder="Enter Telegram"
                className={input}
              />
            </div>

            <div>
              <label htmlFor="emailAddress">Discord</label>
              <input
                value={discord}
                onChange={(e) => {
                  setDiscord(e.target.value);
                }}
                id="emailAddress"
                type="text"
                placeholder="Enter Discord Here"
                className={input}
              />
            </div>
          </div>

          <div className="flex items-center justify-center mt-6">
            <button
              onClick={async () => {
                if (isConnected) {
                  if (verify()) {
                    if (mainnetChains.includes(chain?.id) || testnetChains.includes(chain?.id)) {
                      // setOpenspinner(true);
                      approveTokenWrite()
                    } else {
                      toast.error("This chain is not supported, please switch to supported chain")
                    }
                  }
                } else {
                  toast.error("Please connect your wallet");
                }
              }}
              type="button"
              className="px-24 py-2 leading-5 text-white transition-colors duration-200 transform rounded-md bg-black/60"
            >
              Save
            </button>
          </div>
        </form>
      </section>

      <ResponsiveDialog open={openSpinner}></ResponsiveDialog>
    </main>
  );
};

export default CreatePresale;
