import { useEffect, useState } from "react";

import { AiOutlineChrome, AiFillExclamationCircle } from "react-icons/ai";
import { IoNewspaperOutline } from "react-icons/io5";
// import { Contract } from "ethers";
import { toast } from "react-hot-toast";
import { formatEther, parseEther } from "ethers/lib/utils";
import { presaleAbi, rpc, scanLinks } from "../../config";
import Web3 from "web3";
import { chainsImg } from "../common/Header/NavBar/chaindata";
import FarmAction from "./FarmAction";
import { useAccount } from "wagmi";

const styles = {
  gridDiv: "col-span-4 flex items-center justify-center text-xs tracking-wider",
};

// export const getContract = (library, account, add, abi) => {
//   const signer = library?.getSigner(account).connectUnchecked();
//   var contract = new Contract(add, abi, signer);
//   return contract;
// };

const FarmStats = ({
  tsymbol,
  emergencyTax,
  poolEnd,
  finalized,
  dollarPrice,
  progress,
  cancelled,
  poolStart,
  admin,
  duration,
  remaining,
  userStakedBalance,
  logo,
  symbol,
  userBNBBalance,
  userdollarBalance,
  userTokenbalance,
  usertokenBalancedollar,
  userLPbalance,
  userLPbalanceDollar,
  pendingReward,
  pendingRewardDollar,
  paidrewards,
  paidRewarddollar,
  APR,
  TVL,
  data,
  openSpinner,
  setOpenspinner,
  refAddress,
  params,
  chainId,
  status,
  marketCap
}) => {
  const [amount, setAmount] = useState("0");
  const [Samount, setSAmount] = useState("0");
  const { address } = useAccount();
  // const [saleYes, setSaleYes] = useState(true);
  // const [sellFunction, setSellFunction] = useState("");
  const [bal, setBal] = useState("0");

  const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chainId]));

  useEffect(() => {
    const abc = async () => {
      if (address) {
        const _bal = await web3.eth.getBalance(address);
        setBal(formatEther(_bal));
      }
    };

    abc();
  }, [address]);

  const socials = data && data[20]?.split("=");
  const website = data && socials[0];
  const telegram = data && socials[1];
  const discord = data && socials[2];

  return (
    <>
      <h2 className="text-center xl:text-left tracking-wide">Farm Stats</h2>

      <div className="xl:px-36 2xl:px-44 3xl:px-52 4xl:px-[20vmax] xl:mt-4">
        <div className="bg-[#484959] rounded-t-md">
          <div className="flex justify-end items-center">
            <span className="rounded bg-[#06837F] py-1 px-2 text-xs font-sans">
              New!
            </span>
          </div>

          <div className="flex flex-row justify-between items-center pt-2 pb-4 px-4">
            <div className="flex flex-col xl:flex-row items-center w-full">
              <div className="w-full flex flex-col justify-center">
                <span className="text-lg font-sans font-semibold">
                  Earn {tsymbol}
                </span>
                <span className="text-xs text-gray-100 font-sans italic">
                  Stake {tsymbol}-Bills
                </span>
              </div>

              <div className="w-full mt-3 xl:mt-0 xl:mr-24 flex flex-col justify-center font-sans text-gray-100 text-xs">
                <span>Start: {poolStart}</span>
                <span>End: {poolEnd}</span>
                <span>Duration: {duration} days</span>
                <span>Remaining: {remaining} days</span>
              </div>
            </div>

            <div>
              <img src={logo} alt="logo" className="w-20 h-20" />
            </div>
          </div>
        </div>

        <div className="bg-[#3A3B49]">
          <div className="flex justify-center items-center p-2">
            <a
              href={data && `${website}`}
              target="_blank"
              className="flex items-center text-xs font-light font-sans"
            >
              {tsymbol} <AiOutlineChrome className="ml-2" />
            </a>

            <span className="flex items-center text-xs font-light font-sans ml-4">
              /
            </span>

            <a
              href={data && `${scanLinks[chainId]}/address/${params}`}
              target="_blank"
              className="flex items-center text-xs font-light font-sans ml-4 text-white"
            >
              {tsymbol}-Bills <IoNewspaperOutline className="ml-2 text-white" />
            </a>

            <span className="flex items-center text-xs font-light font-sans ml-4">
              /
            </span>

            <a
              href={data && `${scanLinks[chainId]}/address/${data[3]}`}
              target="_blank"
              className="flex items-center text-xs font-light font-sans ml-4 text-white"
            >
              {tsymbol} <IoNewspaperOutline className="ml-2 text-white" />
            </a>
          </div>

          <div className="grid grid-cols-12 pt-2 px-4 font-sans">
            <div className="col-span-6">
              <div className="flex flex-col justify-center items-start space-y-5 font-semibold">
                <span>{symbol} Balance:</span>
                <span>{tsymbol} Balance:</span>
                <span>{tsymbol}-Bills Staked:</span>
                <span>Pending Rewards:</span>
                <span>Paid Rewards:</span>
              </div>
            </div>

            <div className="col-span-6 flex justify-end items-center">
              <div className="flex flex-col justify-center space-y-4 w-full">
                <div className="rounded-3xl bg-[#484959] p-1 flex justify-around items-center">
                  <span className="text-sm">{Number(bal).toFixed(4)}</span>
                  <span className="text-sm font-light">
                    -${Number(bal * dollarPrice).toFixed(2)}
                  </span>
                </div>
                <div className="rounded-3xl bg-[#484959] p-1 flex justify-around items-center">
                  <span className="text-sm">{userTokenbalance}</span>
                  <span className="text-sm font-light">
                    -${usertokenBalancedollar}
                  </span>
                </div>
                <div className="rounded-3xl bg-[#484959] p-1 flex justify-around items-center">
                  <span className="text-sm">{userLPbalance}</span>
                  <span className="text-sm font-light">
                    -${userLPbalanceDollar}
                  </span>
                </div>
                <div className="rounded-3xl bg-[#484959] p-1 flex justify-around items-center">
                  <span className="text-sm">{pendingReward}</span>
                  <span className="text-sm font-light">
                    -${pendingRewardDollar}
                  </span>
                </div>
                <div className="rounded-3xl bg-[#484959] p-1 flex justify-around items-center">
                  <span className="text-sm">{paidrewards}</span>
                  <span className="text-sm font-light">
                    -${paidRewarddollar}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="py-4 px-4 font-sans">
            <div className="bg-[#FFFBE7] rounded text-black text-xs font-semibold flex justify-center items-center text-center">
              <AiFillExclamationCircle className="mx-2 text-[#FFC107] w-6 h-6" />
              Your staked tokens are locked until: {poolEnd}. If you unstake
              before this date, you will be penalized {emergencyTax}% of your
              staked tokens.
            </div>
          </div>

          <div className="h-[2px] bg-white w-full" />

          <div className="my-4 flex justify-center items-center font-sans">
            <div className="flex flex-col justify-center items-center mr-4">
              <span className="text-sm font-sans font-semibold">APR</span>
              <span className="bg-[#484959] rounded-xl py-1 px-4 text-sm font-semibold">
                {APR}%
              </span>
            </div>

            <div className="flex flex-col justify-center items-center">
              <span className="text-sm font-sans font-semibold">TVL</span>
              <span className="bg-[#484959] rounded-xl py-1 px-4 text-sm font-semibold">
                {TVL}
              </span>
            </div>
          </div>

          <div className="h-[2px] bg-white w-full" />

          {!cancelled && status != "COMPLETED" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>ENTER PURCHASE AMOUNT</p>
              <span style={{ display: "flex" }}>
                <input
                  style={{ color: "black" }}
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <div className="flex flex-col justify-center items-center">
                  <span
                    onClick={async () => {
                      if (data && Number(bal) < Number(formatEther(data[9]))) {
                        setAmount(bal);
                      } else {
                        setAmount(formatEther(data[9]));
                      }
                    }}
                    style={{ cursor: "pointer" }}
                    className="bg-[#484959] rounded py-1 px-4 text-sm"
                  >
                    Max
                  </span>
                </div>
              </span>
            </div>
          )}

          <div className="h-[2px] bg-white w-full mt-5" />

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>ENTER PERCENTAGE</p>
            <span style={{ display: "flex" }}>
              <input
                style={{ color: "black" }}
                value={Samount}
                onChange={(e) => {
                  setSAmount(e.target.value);
                }}
              />
              <div className="flex flex-col justify-center items-center">
                <span
                  onClick={async () => {
                    setSAmount(100);
                  }}
                  style={{ cursor: "pointer" }}
                  className="bg-[#484959] rounded py-1 px-4 text-sm"
                >
                  Max
                </span>
              </div>
            </span>
          </div>

          <div className="my-4 flex justify-center items-center font-sans pt-2 pb-4 ">
            <div className="flex flex-col justify-center items-center">
              <span
                onClick={async () => {
                  setSAmount(String(25));
                }}
                style={{ cursor: "pointer" }}
                className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
              >
                25%
              </span>
            </div>

            <div className="flex flex-col justify-center items-center ml-3">
              <span
                onClick={async () => {
                  setSAmount(String(50));
                }}
                style={{ cursor: "pointer" }}
                className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
              >
                50%
              </span>
            </div>

            <div className="flex flex-col justify-center items-center ml-3">
              <span
                onClick={async () => {
                  setSAmount(String(75));
                }}
                style={{ cursor: "pointer" }}
                className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
              >
                75%
              </span>
            </div>

            <div className="flex flex-col justify-center items-center ml-3">
              <span
                onClick={async () => {
                  setSAmount(String(100));
                }}
                style={{ cursor: "pointer" }}
                className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
              >
                100%
              </span>
            </div>
          </div>

          <div className="h-[2px] bg-white w-full mt-5" />

          <div className="my-4 flex justify-center items-center font-sans pt-2 pb-4 ">
            {!cancelled && status != "COMPLETED" && (
              <div className="flex flex-col justify-center items-center">

                <FarmAction
                  args={[refAddress]}
                  value={amount.length > 0 && amount != "." ? parseEther(amount) : "0"}
                  con={params}
                  abi={presaleAbi}
                  fun="purchaseSafoBill"
                  title="Buy"
                  setOpen={setOpenspinner}
                  setAmount={setAmount}
                  chainId={chainId}
                  success={() => {
                    toast.success("Bought successfully");
                  }}
                  fail={() => {
                    toast.error("Failed to buy");
                  }}
                />
                {/* <span
                  onClick={async () => {
                    // const ref = state.referrer
                    //   ? state.referrer
                    //   : "0x0000000000000000000000000000000000000000";
                    // console.log("referral", ref);
                    writeFunction(
                      "Buy ",
                      "purchaseSafoBill",
                      presaleAbi,
                      params,
                      6000000,
                      [refAddress],
                      parseEther(amount),
                      () => {
                        toast.success("Buy function successful");
                      }
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
                >
                  Buy
                </span> */}
              </div>
            )}

            {data &&
              userStakedBalance &&
              Number(userStakedBalance) == 0 ? null : cancelled ? (
                <div className="flex flex-col justify-center items-center mx-4 ml-3 mr-0">
                  <FarmAction
                    args={[Samount.length > 0 && Samount != "." ? String(+Samount * 100) : "0"]}
                    value="0"
                    con={params}
                    abi={presaleAbi}
                    fun="refund"
                    title="Refund"
                    setOpen={setOpenspinner}
                    setAmount={setSAmount}
                    chainId={chainId}
                    success={() => {
                      toast.success("Refund successfully");
                    }}
                    fail={() => {
                      toast.error("Failed to Refund");
                    }}
                  />
                  {/* <span
                    onClick={async () => {
                      writeFunction(
                        "Refund ",

                        "refund",
                        presaleAbi,
                        params,
                        500000,
                        [parseEther(Samount.toString())],
                        0,
                        () => {
                          toast.success("Refund function successful");
                        }
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
                  >
                    Refund
                  </span> */}
                </div>
              ) : finalized && remaining <= 0 ? (
                <div className="flex flex-col justify-center items-center mx-4 ml-3">
                  <FarmAction
                    args={[Samount.length > 0 && Samount != "." ? String(+Samount * 100) : "0"]}
                    value="0"
                    con={params}
                    abi={presaleAbi}
                    fun="redeemSafoBill"
                    title="Sell"
                    setOpen={setOpenspinner}
                    setAmount={setSAmount}
                    chainId={chainId}
                    success={() => {
                      toast.success("Sell successfully");
                    }}
                    fail={() => {
                      toast.error("Failed to Sell");
                    }}
                  />
                  {/* <span
                    onClick={async () => {
                      writeFunction(
                        "Sell ",

                        "redeemSafoBill",
                        presaleAbi,
                        params,
                        500000,
                        [parseEther(Samount.toString())],
                        0,
                        () => {
                          toast.success("Sell function successful");
                        }
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
                  >
                    Sell
                  </span> */}
                </div>
              ) : (
              <div className="flex flex-col justify-center items-center mx-4 ml-3 mr-0">
                <FarmAction
                  args={[]}
                  value="0"
                  con={params}
                  abi={presaleAbi}
                  fun="cancelInvestment"
                  title="Cancel Contribution"
                  setOpen={setOpenspinner}
                  setAmount={setSAmount}
                  chainId={chainId}
                  success={() => {
                    toast.success("Contribution Cancelled successfully");
                  }}
                  fail={() => {
                    toast.error("Failed to Cancel Contribution");
                  }}
                />
                {/* <span
                  onClick={async () => {
                    writeFunction(
                      "Emergency Sell ",

                      "emergencySale",
                      presaleAbi,
                      params,
                      500000,
                      [parseEther(Samount.toString())],
                      0,
                      () => {
                        toast.success("Emergency Sell successful");
                      }
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
                >
                  {" "}
                  Emergency Sell
                </span> */}
              </div>
            )}

            <div className="flex flex-col justify-center items-center ml-3">
              <FarmAction
                args={[]}
                value="0"
                con={params}
                abi={presaleAbi}
                fun="claimRewards"
                title="Harvest"
                setOpen={setOpenspinner}
                setAmount={setSAmount}
                chainId={chainId}
                success={() => {
                  toast.success("Harvest successfully");
                }}
                fail={() => {
                  toast.error("Failed to Harvest");
                }}
              />
              {/* <span
                onClick={async () => {
                  writeFunction(
                    "harvest ",

                    "claimRewards",
                    presaleAbi,
                    params,
                    500000,
                    [],
                    0,
                    () => {
                      toast.success("Harvest function successful");
                    }
                  );
                }}
                style={{ cursor: "pointer" }}
                className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
              >
                Harvest
              </span> */}
            </div>

            {address && admin && address == admin ? (
              <div className="flex flex-col justify-center items-center ml-3">
                <FarmAction
                  args={[]}
                  value="0"
                  con={params}
                  abi={presaleAbi}
                  fun="cancel"
                  title="Cancel Sale As Sale Owner"
                  setOpen={setOpenspinner}
                  setAmount={setSAmount}
                  chainId={chainId}
                  success={() => {
                    toast.success("Cancel successfully");
                  }}
                  fail={() => {
                    toast.error("Failed to Cancel");
                  }}
                />
                {/* <span
                  onClick={async () => {
                    writeFunction(
                      "harvest ",

                      "claimRewards",
                      presaleAbi,
                      params,
                      500000,
                      [],
                      0,
                      () => {
                        toast.success("Harvest function successful");
                      }
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
                >
                  Cancel
                </span> */}
              </div>
            ) : null}
          </div>
        </div>

        <div className="bg-[#3A3B49] mt-4 xl:px-12 pb-5">
          <div className="flex justify-center items-center py-4 px-12">
            <div className="w-full text-center bg-[#484959] py-2 px-4 rounded text-xs tracking-wide">
              SOFT CAP: {data && formatEther(data[0])} {symbol} - ${" "}
              {data &&
                Number(Number(formatEther(data[0])) * dollarPrice).toFixed(2)}
            </div>
          </div>

          <div className="px-4">
            <div className="w-full bg-[#484959] rounded">
              <div
                style={{ width: `${progress <= 100 ? progress : 100}%` }}
                className="bg-[#9DFF94] text-xs font-medium text-black text-right p-0.5 leading-none rounded w-[53%]"
              >
                {progress <= 100 ? progress.toFixed(0) : 100}%
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-2 mt-4 py-1 bg-[#484959]">
            <div className={`${styles.gridDiv}`}>Farm Duration</div>

            <div className={`${styles.gridDiv}`}>Liquidity</div>

            <div className={`${styles.gridDiv}`}>Total Raised</div>
          </div>

          <div className="grid grid-cols-12 gap-2 pt-3">
            <div className={`${styles.gridDiv}`}>{data && data[14]} Days</div>

            <div className={`${styles.gridDiv}`}>
              {data && data[11]} {"%"}
            </div>

            <div className={`${styles.gridDiv}`}>
              {data && Number(formatEther(data[5])).toFixed(5)} {symbol}
            </div>
          </div>

          <div className="grid grid-cols-12 gap-2 pt-2">
            <div className="flex items-center col-span-9">
              <div className="py-1 pl-7 bg-[#484959] text-xs tracking-wide w-full rounded">
                Fully Diluted MC Estimate: ${" "}
                {marketCap &&
                  marketCap}
              </div>
            </div>

            <div className="flex items-center col-span-3">
              <img src={chainsImg[chainId].icon} alt="bnb" className="w-8 h-8 ml-2" />
            </div>
          </div>

          {/* <div className="flex justify-center items-center py-1 bg-[#484959] text-xs tracking-wide mt-4">
                    Time Left: 07:11:58:09
                </div> */}

          {address && (
            <div className="flex justify-center items-center py-1 bg-[#484959] text-xs tracking-wide mt-4">
              My Contribution:{" "}
              {data && userBNBBalance}{" "}
              {symbol} - {userTokenbalance} {data && data[15]}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FarmStats;
