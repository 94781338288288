import { useState } from "react";
import LayoutContext from "./LayoutContext";

// eslint-disable-next-line react/prop-types
const LayoutProvider = ({ children }) => {
    const [active, setActive] = useState(1);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [showTestnet, setShowTestnet] = useState(false);

    const mainnetChains = [1, 56];
    const testnetChains = [5, 97, 80001];



    return (
        <LayoutContext.Provider value={{ active, setActive, mobileOpen, setMobileOpen, showTestnet, setShowTestnet, mainnetChains, testnetChains }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;