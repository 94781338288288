import { toast } from "react-hot-toast";
import { useState } from "react";
import { formatEther, parseEther } from "ethers/lib/utils";
import { IERC20, presaleAbi } from "../../config";
import { isMobile } from "react-device-detect";
import { useAccount, useContractWrite, useWaitForTransaction } from "wagmi";
import AdminAction from "./AdminAction";

const AdminPanel = ({ openSpinner, setOpenspinner, params, chainId, data, contractBalance, totalStaked, APR }) => {
  const { address } = useAccount();
  const [reward, setReward] = useState(0);
  const [referral, setReferral] = useState(0);
  const [tokens, setTokens] = useState(0);
  const [tokensSell, setTokensSell] = useState(0);


  // writeFunction(
  //   "send tokens",

  //   "approve",
  //   IERC20,
  //   data[3],
  //   500000,
  //   [params, parseEther(tokens)],
  //   0,
  //   () => {
  //     writeFunction(
  //       "send tokens",

  //       "depositToken",
  //       presaleAbi,
  //       params,
  //       500000,
  //       [parseEther(tokens)],
  //       0,
  //       () => {
  //         setTokens("");
  //         toast.success("tokens send successful");
  //       }
  //     );
  //   }
  // );

  const { write: approveTokenWrite, data: approveTokenData } = useContractWrite(
    {
      address: data ? data[3] : "0x2F56fDA413aE38E87a25b9d3e2C41B2d08090418",
      abi: IERC20,
      functionName: 'approve',
      args: [params, tokens.length > 0 && tokens != "." ? parseEther(tokens) : "0"],
      onError: (error) => {
        var str = JSON.stringify(error)
        var obj = JSON.parse(str)
        var reason = obj.cause.reason
        reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
        setOpenspinner(false);

      }
    }
  )

  const { write: buyTokenWrite, data: buyTokenData } = useContractWrite(
    {
      address: params,
      abi: presaleAbi,
      functionName: 'depositToken',
      args: [tokens.length > 0 && tokens != "." ? parseEther(tokens) : "0"],
      onError: (error) => {
        var str = JSON.stringify(error)
        var obj = JSON.parse(str)
        var reason = obj.cause.reason
        reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
        setOpenspinner(false);

      }
    }
  )

  const approveTokenWait = useWaitForTransaction({
    hash: approveTokenData?.hash,
    onSuccess() {
      buyTokenWrite();
    }
  })

  const buyTokenWait = useWaitForTransaction({
    hash: buyTokenData?.hash,
    onSuccess() {
      setOpenspinner(false);
      setTokens("");
      toast.success("tokens send successful");
    }
  })

  const { write: approveSellTokenWrite, data: approveSellTokenData } = useContractWrite(
    {
      address: data ? data[3] : "0x2F56fDA413aE38E87a25b9d3e2C41B2d08090418",
      abi: IERC20,
      functionName: 'approve',
      args: [params, tokensSell.length > 0 && tokensSell != "." ? parseEther(tokensSell) : "0"],
      onError: (error) => {
        var str = JSON.stringify(error)
        var obj = JSON.parse(str)
        var reason = obj.cause.reason
        reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
        setOpenspinner(false);

      }
    }
  )

  const { write: buySellTokenWrite, data: buySellTokenData } = useContractWrite(
    {
      address: params,
      abi: presaleAbi,
      functionName: 'depositTokenForSale',
      args: [tokensSell.length > 0 && tokensSell != "." ? parseEther(tokensSell) : "0"],
      onError: (error) => {
        var str = JSON.stringify(error)
        var obj = JSON.parse(str)
        var reason = obj.cause.reason
        reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
        setOpenspinner(false);

      }
    }
  )

  const approveSellTokenWait = useWaitForTransaction({
    hash: approveSellTokenData?.hash,
    onSuccess() {
      buySellTokenWrite();
    }
  })

  const buySellTokenWait = useWaitForTransaction({
    hash: buySellTokenData?.hash,
    onSuccess() {
      setOpenspinner(false);
      setTokensSell("0");
      toast.success("tokens send successful");
    }
  })



  const needed = data && Number(Number(totalStaked) * APR / 100 / 365 * Number(data[14])).toFixed(8)

  //console.log("needed",totalStaked,APR,data[14])
  return (
    <div>
      <h1 style={{ position: "relative", left: "42%", width: "fit-content" }}>
        Admin Panel
      </h1>
      <div
        style={
          isMobile
            ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }
            : { display: "flex" }
        }
      >
        <div
          style={{
            width: "300px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <h3>Add Reward</h3>
          <div>
            <p>Enter reward value:</p>
            <input
              style={{ color: "black" }}
              value={reward}
              onChange={(e) => {
                setReward(e.target.value);
              }}
            />
          </div>
          <div>
            <AdminAction
              args={[reward.length > 0 && reward != "." ? parseEther(reward * (data && formatEther(data[0]) * 100000 * data[14] / (365 * 100))).toString() : "0"]}
              value="0"
              con={params}
              abi={presaleAbi}
              fun="setPoolRewards"
              title="Set Reward"
              setOpen={setOpenspinner}
              setAmount={setReward}
              chainId={chainId}
              success={() => {
                toast.success("Reward set successfully");
              }}
              fail={() => {
                toast.error("Failed to set Reward");
              }}
            />
            {/* <div
              style={{
                background: "grey",
                textAlign: "center",
                borderRadius: "10px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                const APRFactor = data && formatEther(data[0]) * 100000 * data[14] / (365 * 100)
                console.log("reward", reward, APRFactor, Number(formatEther(data[0])), Number(data[14]), parseEther((reward * APRFactor).toString()))


                writeFunction(
                  "set Reward ",

                  "setPoolRewards",
                  presaleAbi,
                  params,
                  500000,
                  [parseEther((reward * APRFactor).toString())],
                  0,
                  () => {
                    setReward("");
                    toast.success("Reward set successful");
                  }
                );

              }}
            >
              Set Reward
            </div> */}
          </div>
        </div>

        <div
          style={{
            width: "300px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <h3>Set Referral</h3>
          <div>
            <p>Enter referral bonus:</p>
            <input
              style={{ color: "black" }}
              value={referral}
              onChange={(e) => {
                setReferral(e.target.value);
              }}
            />
          </div>
          <div>
            <AdminAction
              args={[referral.length > 0 && referral != "." ? referral : "0"]}
              value="0"
              con={params}
              abi={presaleAbi}
              fun="setRefferralPercentage"
              title="Set Referral Bonus"
              setOpen={setOpenspinner}
              setAmount={setReferral}
              chainId={chainId}
              success={() => {
                toast.success("Referral Bonus Set successfully");
              }}
              fail={() => {
                toast.error("Failed to Set Referral Bonus");
              }}
            />
            {/* <div
              style={{
                background: "grey",
                textAlign: "center",
                borderRadius: "10px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                writeFunction(
                  "set Referral ",

                  "setRefferralPercentage",
                  presaleAbi,
                  params,
                  500000,
                  [referral],
                  0,
                  () => {
                    setReferral("");
                    toast.success("Referral set successful");
                  }
                );
              }}
            >
              Set Referral Bonus
            </div> */}
          </div>
        </div>

        <div
          style={{
            width: "300px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <h3>Add Badges</h3>
          {/* {console.log("Kyc", data[10])} */}
          <div>
            <AdminAction
              args={data[10]?.length > 0 ? [[true, [...data[10]][1], [...data[10]][2]]] : [[true, false, false]]}
              value="0"
              con={params}
              abi={presaleAbi}
              fun="setBadges"
              title="KYC"
              setOpen={setOpenspinner}
              setAmount={setReferral}
              chainId={chainId}
              success={() => {
                toast.success("Badge set successfully");
              }}
              fail={() => {
                toast.error("Failed to Set Badge");
              }}
            />
            {/* <div
              style={{
                background: "grey",
                textAlign: "center",
                borderRadius: "10px",
                marginBottom: "5px",
                cursor: "pointer",
                width: "150px",
              }}
              onClick={() => {
                const array = [...data[10]];
                const KYC = true;
                const Audit = array[1];
                const SAFO = array[2];
                const copyArray = [KYC, Audit, SAFO];
                writeFunction(
                  "Add Badge ",

                  "setBadges",
                  presaleAbi,
                  params,
                  500000,
                  [copyArray],
                  0,
                  () => {
                    toast.success("Badge set successful");
                  }
                );
              }}
            >
              KYC
            </div> */}
            <AdminAction
              args={data[10]?.length > 0 ? [[[...data[10]][0], true, [...data[10]][2]]] : [[false, true, false]]}
              value="0"
              con={params}
              abi={presaleAbi}
              fun="setBadges"
              title="AUDIT"
              setOpen={setOpenspinner}
              setAmount={setReferral}
              chainId={chainId}
              success={() => {
                toast.success("Badge set successfully");
              }}
              fail={() => {
                toast.error("Failed to Set Badge");
              }}
            />
            {/* <div
              style={{
                background: "grey",
                textAlign: "center",
                borderRadius: "10px",
                marginBottom: "5px",
                cursor: "pointer",
                width: "150px",
              }}
              onClick={() => {
                const array = [...data[10]];
                const KYC = array[0];
                const Audit = true;
                const SAFO = array[2];
                const copyArray = [KYC, Audit, SAFO];
                writeFunction(
                  "Add Badge ",

                  "setBadges",
                  presaleAbi,
                  params,
                  500000,
                  [copyArray],
                  0,
                  () => {
                    toast.success("Badge set successful");
                  }
                );
              }}
            >
              AUDIT
            </div> */}
            <AdminAction
              args={data[10]?.length > 0 ? [[[...data[10]][0], [...data[10]][1], true]] : [[false, false, true]]}
              value="0"
              con={params}
              abi={presaleAbi}
              fun="setBadges"
              title="SAFO"
              setOpen={setOpenspinner}
              setAmount={setReferral}
              chainId={chainId}
              success={() => {
                toast.success("Badge set successfully");
              }}
              fail={() => {
                toast.error("Failed to Set Badge");
              }}
            />
            {/* <div
              style={{
                width: "150px",
                background: "grey",
                textAlign: "center",
                borderRadius: "10px",
                marginBottom: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                const array = [...data[10]];
                const KYC = array[0];
                const Audit = array[1];
                const SAFO = true;
                const copyArray = [KYC, Audit, SAFO];
                writeFunction(
                  "Add Badge ",

                  "setBadges",
                  presaleAbi,
                  params,
                  500000,
                  [copyArray],
                  0,
                  () => {
                    toast.success("Badge set successful");
                  }
                );
              }}
            >
              SAFO
            </div> */}
          </div>
        </div>
      </div>

      <AdminAction
        args={[]}
        value="0"
        con={params}
        abi={presaleAbi}
        fun="cancel"
        title="Cancel Pool"
        setOpen={setOpenspinner}
        setAmount={setReferral}
        chainId={chainId}
        success={() => {
          toast.success("Cancel Pool successfully");
        }}
        fail={() => {
          toast.error("Failed to Cancel Pool");
        }}
      />

      {/* <div
        onClick={() => {
          writeFunction(
            "Cancel Pool ",

            "cancel",
            presaleAbi,
            params,
            500000,
            [],
            0,
            () => {
              toast.success("Cancel Pool successful");
            }
          );
        }}
        style={{
          cursor: "pointer",
          position: "relative",
          left: "42%",
          width: "150px",
          marginTop: "15px",
          borderRadius: "15px",
          background: "red",
          textAlign: "center",
        }}
      >
        CANCEL POOL
      </div> */}

      <div style={{ marginTop: "15px", position: "relative", width: "40%", left: "30%", textAlign: "center" }}>
        <h2>Tokens for reward in the Contract: <span>{contractBalance}</span></h2>
        <h2>Balance Needed: <span>{needed}</span></h2>
        <div>
          <p>Enter tokens to send</p>
          <input
            type="number"
            style={{ color: "black" }}
            value={tokens}
            onChange={(e) => {
              setTokens(e.target.value);
            }}
          />
        </div>
        <div>
          <div
            style={{
              background: "grey",
              textAlign: "center",
              borderRadius: "10px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              approveTokenWrite?.();

            }}
          >
            Send Tokens
          </div>
        </div>
      </div>

      <div style={{ marginTop: "15px", position: "relative", width: "40%", left: "30%", textAlign: "center" }}>
        {/* <h2>Balance Needed: <span>{needed}</span></h2> */}
        <div>
          <p>Enter tokens to sell</p>
          <input
            style={{ color: "black" }}
            value={tokensSell}
            onChange={(e) => {
              setTokensSell(e.target.value);
            }}
          />
        </div>
        <div>
          <div
            style={{
              background: "grey",
              textAlign: "center",
              borderRadius: "10px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              approveSellTokenWrite?.();
            }}
          >
            Send Tokens
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center flex-wrap gap-4">
        <div style={{ marginTop: "15px", width: "30%" }}>
          <AdminAction
            args={[]}
            value="0"
            con={params}
            abi={presaleAbi}
            fun="withdrawFund"
            title=" Withdraw Funds"
            setOpen={setOpenspinner}
            setAmount={setReferral}
            chainId={chainId}
            success={() => {
              toast.success("Funds Withdraw successfully");
            }}
            fail={() => {
              toast.error("Failed to Withdraw Funds");
            }}
          />
          {/* <div
            style={{
              background: "grey",
              textAlign: "center",
              borderRadius: "10px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              writeFunction(
                "withdraw funds",
                "withdrawFund",
                presaleAbi,
                params,
                500000,
                [],
                0,
                () => {
                  toast.success("Funds Withdraw successful");
                }
              );
            }}
          >
            Withdraw Funds
          </div> */}
        </div>
        <div style={{ marginTop: "15px", width: "30%" }}>
          <AdminAction
            args={[]}
            value="0"
            con={params}
            abi={presaleAbi}
            fun="withdrawTokens"
            title=" Withdraw Tokens"
            setOpen={setOpenspinner}
            setAmount={setReferral}
            chainId={chainId}
            success={() => {
              toast.success("Tokens Withdraw successfully");
            }}
            fail={() => {
              toast.error("Failed to Withdraw Tokens");
            }}
          />
          {/* <div
            style={{
              background: "grey",
              textAlign: "center",
              borderRadius: "10px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              writeFunction(
                "withdraw tokens",
                "withdrawTokens",
                presaleAbi,
                params,
                500000,
                [],
                0,
                () => {
                  toast.success("Tokens Withdraw successful");
                }
              );
            }}
          >
            Withdraw Tokens
          </div> */}
        </div>
        <div style={{ marginTop: "15px", width: "30%" }}>
          <AdminAction
            args={[]}
            value="0"
            con={params}
            abi={presaleAbi}
            fun="withdrawLPTokens"
            title=" Withdraw Lp Tokens"
            setOpen={setOpenspinner}
            setAmount={setReferral}
            chainId={chainId}
            success={() => {
              toast.success("Lp Tokens Withdraw successfully");
            }}
            fail={() => {
              toast.error("Failed to Withdraw Lp Tokens");
            }}
          />
          {/* <div
            style={{
              background: "grey",
              textAlign: "center",
              borderRadius: "10px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              writeFunction(
                "withdraw Lps",
                "withdrawLPTokens",
                presaleAbi,
                params,
                500000,
                [],
                0,
                () => {
                  toast.success("Lps Withdraw successful");
                }
              );
            }}
          >
            Withdraw Lp
          </div> */}
        </div>
        <div style={{ marginTop: "15px", width: "30%" }}>
          <AdminAction
            args={[]}
            value="0"
            con={params}
            abi={presaleAbi}
            fun="finalizePool"
            title="Shift Sale To Farm Dapp"
            setOpen={setOpenspinner}
            setAmount={setReferral}
            chainId={chainId}
            success={() => {
              toast.success("Shift Sale To Farm Dapp successfully");
            }}
            fail={() => {
              toast.error("Failed to Shift Sale To Farm Dapp");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
