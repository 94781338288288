import { BsPlayFill } from 'react-icons/bs'

const VideoSection = ({data}) => {

  const youtubeArray = data && data[16].split("watch?v=");
  const youtubeVideo =
    data && `https://www.youtube.com/embed/${youtubeArray[1]}`;
  
  const styles = { position: "inherit", left: "0px", top: "484px" }
    return (
    <div className='flex justify-center items-center'>
        <div style={{border:"1px solid red"}} className='w-full md:w-9/12 xl:w-7/12 3xl:w-4/12'>
        {data && data[16].includes("youtube") ? (
                <iframe
                  width="100%"
                  height="372px"
                  style={styles}
                  //                src={"https://www.youtube.com/embed/eI4an8aSsgw"}
                  src={data && youtubeVideo}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              ) : (
                <img
                  width="100%"
                  height="372px"
                  style={styles}
                  //                src={"https://www.youtube.com/embed/eI4an8aSsgw"}
                  src={data && data[16]}
                ></img>
              )}         
        </div>
    </div>
  )
}

export default VideoSection