import background_main from "../../../assets/images/footer/background_main.svg";
import web_icon from "../../../assets/images/footer/web_icon.svg";
import telegram_icon from "../../../assets/images/footer/telegram_icon.svg";
import logo from "../../../assets/images/global/logo.svg";
import text_left from "../../../assets/images/footer/text_left.svg";
import text_right from "../../../assets/images/footer/text_right.svg";

const Footer = () => {
  return (
    <div className="relative">
        <div 
            style={{
                backgroundImage: `url(${background_main})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "absolute",
                bottom: "0",
                right: "0",
                zIndex: "-1",
            }}
            className="w-full h-full"
        />

        <div className="flex justify-center z-10 ">
            <div className="flex flex-col justify-center items-center">
                <div className="flex items-center pt-2 md:pt-5">
                    <img src={text_left} className="w-11 h-11 mr-6" />
                    <span className="text-white text-xs font-bold uppercase">Safeone links</span>
                    <img src={text_right} className="w-11 h-11 ml-6" />
                </div>

                <div>
                    <img src={logo} className="w-14 h-14 md:w-40 md:h-40" />
                </div>

                <div className="flex items-center md:pb-5">
                    <a 
                    href="https://safeonechain.com/"
                    target="_blank"
                    >
                    <img src={web_icon} className="w-5 h-5 md:w-9 md:h-9 mr-2 md:mr-4 cursor-pointer" />
                    </a>
                    <a 
                    href="https://t.me/SafeOneChain"
                    target="_blank"
                    >
                    <img src={telegram_icon} className="w-5 h-5 md:w-9 md:h-9 ml-2 md:ml-4 cursor-pointer" />
                    </a>


                </div>

                <div className="flex items-center py-3 md:pb-14 md:pt-8">
                    <span className="text-gray-400 text-xs">Copyright © 2023. All Rights Reserved SAFO</span>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Footer