

import web_icon from "../../assets/images/stakeDetails/web_icon.svg"
import telegram_icon from "../../assets/images/stakeDetails/telegram_icon.svg"
import discord_icon from "../../assets/images/stakeDetails/discord_icon.svg"
import container_img from "../../assets/images/home/stake_container.svg"
const styles = {
    tag: 'text-center py-1 px-4 text-xs uppercase rounded-sm',
}

const DetailSection = ({logo,name,status,icon,badges,data}) => {
  
    const socials = data && data[20]?.split("=")
    const website = data && socials[0]
    const telegram = data && socials[1]
    const discord = data && socials[2]


    const banner = data && (data[16]?.includes(".jpg")  || data[16]?.includes(".png") || 
    data[16]?.includes(".jpeg") || data[16]?.includes(".gif") ) 
    ? data[16] : container_img


    // console.log("socials",data[20])
    return (
    <div className={`details_section relative flex flex-col xl:flex-row justify-center xl:justify-between items-center`}>
        <div className="flex flex-col xl:flex-row items-center xl:pb-4 xl:pl-4">
            <img src={logo} alt="logo" className={`w-16 h-16`} />
            <div className="flex flex-col justify-center items-center xl:items-start xl:pt-12">
                <h1 className="tracking-wide">{name}</h1>
                {/* <span className="tracking-wide text-xs pt-1">Ends in: 07:11:58:09</span> */}
                <span className="tracking-wide text-sm text-[#A3FF12] uppercase pt-1">{status}</span>
            </div>
        </div>

        <div className="py-2 xl:py-0">
            <img src={icon} alt="eth" className={`w-9 h-9`} />
        </div>

        <div className="pr-2 py-2 xl:py-0">
            <div className="flex items-center">
                <div className={`${styles.tag} ${badges && badges[0] ? 'bg-[#81B441]' : 'bg-[#4E4E5A]'}`}>
                    KYC
                </div>

                <div className={`${styles.tag} ${ badges &&badges[1] ? 'bg-[#81B441]' : 'bg-[#4E4E5A]'} mx-4 xl:mx-2`}>
                    Audit
                </div>

                <div className={`${styles.tag} ${badges &&badges[2] ? 'bg-[#81B441]' : 'bg-[#4E4E5A]'}`}>
                    Safo
                </div>
            </div>

            <div className="flex justify-center xl:justify-end items-center pb-4 pt-6 xl:pt-0 xl:pb-0 xl:translate-y-5">
                <div>
                    <a 
                    href={data && website}
                    target="_blank"
                    >
                    <img src={web_icon} alt="web_icon" className={`w-7 h-7 xl:w-5 xl:h-5 cursor-pointer`} />
                    </a>

                </div>

                <div className="mx-3">
                <a 
                    href={data && telegram}
                    target="_blank"
                    >
                    <img src={telegram_icon} alt="telegram_icon" className={`w-7 h-7 xl:w-5 xl:h-5 cursor-pointer`} />
                    </a>

                </div>

                <div>
                <a 
                    href={data && discord}
                    target="_blank"
                    >
                    <img src={discord_icon} alt="discord_icon" className={`w-7 h-7 xl:w-5 xl:h-5 cursor-pointer`} />
                    </a>

                </div>
            </div>
        </div>
    </div>

  )
}

export default DetailSection