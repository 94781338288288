import website from "../images/header/website.svg";
import safo_platform from "../images/header/safo_platform.svg";
import locker from "../images/header/locker.svg";
import otc_farms from "../images/header/otc_farms.svg";

const headerData = [
    {
        title: "Website",
        link: "https://safeonechain.com/",
        icon: website
    },
    {
        title: "Safo Platform",
        link: "/",
        icon: safo_platform
    },
    {
        title: "Locker (soon)",
        link: "/locker",
        icon: locker
    },
    {
        title: "OTC Farms",
        link: "https://finance.safeonechain.com/farms",
        icon: otc_farms
    }
];

export default headerData;