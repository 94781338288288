

const ProjectDetails = ({desc}) => {
  return (
    <>
        <h2 className="tracking-wider">Project Details</h2>

        <div className='xl:w-9/12 3xl:w-4/12'>
            <p className="text-xs tracking-wide text-white/70 mt-2 leading-5">
                {desc}
            </p>


        </div>

    </>
  )
}

export default ProjectDetails