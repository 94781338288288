import background_main from "../../../assets/images/cta/background_main.svg";

const CTA = () => {
  return (
    <div className="relative">
      <div
        style={{
          backgroundImage: `url(${background_main})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          bottom: "0",
          right: "0",
          zIndex: "-1",
        }}
        className="w-full h-full"
      />

      <div className="flex flex-col justify-center items-center z-10">
        <span className="text-white text-xl 2xl:text-2xl font-bold tracking-wider uppercase pt-3 md:pt-2 lg:pt-4 xl:pt-6 2xl:pt-8 w-9/12 xs:w-8/12 sm:w-5/12 lg:w-7/12 xl:w-4/12 2xl:w-[15em] text-center pb-44">
          SAFO OTC FARM PRESALE PLATFORM
        </span>

        <span className="text-gray-400 text-xs -translate-y-10 lg:-translate-y-24">
          JOIN SAFO TO KEEP UP TO DATE WITH NEWS & UPDATES
        </span>

        <button
          onClick={()=> window.open("https://t.me/SafeOnechain/")}
          type="button"
          className="bg-[#484959] text-white text-xs uppercase -translate-y-5 lg:-translate-y-12 px-6 py-2"
        >
          Join us now
        </button>
      </div>
    </div>
  );
};

export default CTA;
