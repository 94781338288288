import CTA from "../Cta";
import Footer from "../Footer";
import BurgerMenu from "../Header/BurgerMenu";
import NavBar from "../Header/NavBar";
import SideBar from "../Header/SideBar";

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
    return (
        <div className="flex bg-background" id="layout">
            <aside className={`hidden h-screen lg:flex flex-col shadow-lg sticky top-0 left-0 bg-[#222231] z-20`}>
                <SideBar />
            </aside>

            <aside className={`lg:hidden z-20`}>
                <BurgerMenu />
            </aside>

            <div className={`min-h-screen flex flex-col w-full`}>
                <header className={`z-10`}>
                    <NavBar />
                </header>

                <div className={`overflow-y-auto w-full flex-grow`}>
                    {children}
                </div>

                <section className="py-10 lg:py-24 lg:px-44 z-10">
                    <CTA />
                </section>
 
                <footer className="z-10">
                    <Footer />
                </footer>
            </div>
        </div>
    );
}

export default Layout;