import StakeContainer from "../../components/Home/StakeContainer"
import { useEffect, useState } from "react";
import { LaunchPadABI, launchPadAdd, rpc } from "../../config";
import Web3 from "web3";
import { useLayoutContext } from "../../contexts/LayoutContext";
import Switch from "react-switch";

const styles = {
  padding: 'p-2 md:px-32 lg:px-36 xl:px-40 2xl:px-64'
}

const Home = () => {
  const { showTestnet, mainnetChains, testnetChains, setShowTestnet } = useLayoutContext();
  const [farmChainArray, setFarmChainArray] = useState([]);

  useEffect(() => {
    setFarmChainArray([])
    if (showTestnet) {
      xyz();
    } else {
      abc();
    }
  }, [showTestnet]);

  const abc = async () => {
    let _farm = []
    let _farm2 = []

    await Promise.all(
      mainnetChains.map(async (chain, e) => {
        const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain]));
        const contractR = new web3.eth.Contract(LaunchPadABI, launchPadAdd[chain]);
        const _getFarm = await contractR.methods.getFarmArray().call();
        if (_getFarm.length > 0) {
          _farm.push(..._getFarm)
          _farm2.push({ chainId: chain, farms: [..._getFarm] })
        }
      })
    )
    setFarmChainArray(_farm2);
  };


  const xyz = async () => {
    let _farm = []
    await Promise.all(
      testnetChains.map(async (chain, e) => {
        const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain]));
        const contractR = new web3.eth.Contract(LaunchPadABI, launchPadAdd[chain]);
        const _getFarm = await contractR.methods.getFarmArray().call();
        if (_getFarm.length > 0) {
          _farm.push({ chainId: chain, farms: [..._getFarm] })
        }
      })
    )
    setFarmChainArray(_farm);
  };
  // console.log(farmChainArray,"farmChainArray")

  return (
    <main className={`${styles.padding}`}>
      <div className="flex items-end justify-end">
        <label className="flex items-center justify-center" htmlFor="material-switch">

          <Switch
            checked={showTestnet}
            onChange={(checked) => {
              setShowTestnet(checked)
            }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
          <span className="text-sm text-white ml-2">Show Testnet</span>
        </label>
      </div>
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8 lg:gap-12 xl:gap-16 2xl:gap-20 3xl:gap-36 4xl:gap-[20rem]">

        {farmChainArray.length > 0 && farmChainArray.map((v, e) => (
          <>
            {v.farms.length > 0 && v.farms.map((v2, e2) => (
              <StakeContainer
                farmDetails={v2}
                chainId={v.chainId}
              />
            ))}
          </>
        )
        )}
      </section>
    </main>
  )
}

export default Home