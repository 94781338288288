// import dayjs from "dayjs";
// import { Contract, ethers } from "ethers";
// import { toast } from "react-hot-toast";
// import Web3 from "web3";
// import {
//   LaunchPadABI,
//   LaunchPadAdd,
//   tokenLauncherAbi,
//   tokenlauncherAdd,
// } from "../../config";
// import { tokenAbi } from "../contracts/token";

export const shortAddress = (str) => {
  if (str) {
    if (str.length < 10) return str;
    return `${str.slice(0, 5)}...${str.slice(-5)}`;
  } else {
    return "";
  }
};
export const toHex = (num) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

// export const formatFromWei = (str, decimal) => {
//   if (str) {
//     if (str.length < 1) return str;
//     if (decimal === 9) {
//       return Number(Web3.utils.fromWei(str, "Gwei")).toFixed(2);
//     }
//     return Web3.utils.fromWei(str, "ether");
//   }
// };
// export const formatToWei = (str, decimal) => {
//   str = `${str}`;
//   if (str) {
//     if (str.length < 1) return str;
//     if (decimal === 9) {
//       return Web3.utils.toWei(str, "Gwei");
//     }
//     return Web3.utils.toWei(str, "ether");
//   }
// };

// export const formatFromWei2 = (str, decimal = 18) => {
//   if (!str) return "";
//   str = `${str}`;
//   if (decimal === 18) {
//     return Web3.utils.fromWei(str, "ether");
//   } else if (decimal === 9) {
//     return Web3.utils.fromWei(str, "Gwei");
//   } else {
//     return (+str / 10 ** decimal).toString();
//   }
// };
// export const formatToWei2 = (str, decimal = 18) => {
//   console.log(str);
//   if (!str) return "";
//   str = `${str}`;
//   if (decimal === 18) {
//     return Web3.utils.toWei(str, "ether");
//   } else if (decimal === 9) {
//     return Web3.utils.toWei(str, "Gwei");
//   } else {
//     return (+str * 10 ** decimal).toString();
//   }
// };
// export const toUnix = (string) => {
//   return dayjs(string).unix();
// };

// // export const getContract = async (library, token, abi) => {
// //   try {
// //     let _contract = null;
// //     if (token && !abi) {
// //       _contract = await new library.eth.Contract(tokenAbi, token);
// //     } else if (token && abi) {
// //       _contract = await new library.eth.Contract(abi, token);
// //     } else {
// //       _contract = await new library.eth.Contract(contractAbi, contractAddress);
// //     }
// //     return { ok: true, contract: _contract };
// //   } catch (error) {
// //     console.log(error);
// //     return { ok: false, contract: null };
// //   }
// // };
// export const getProvider = (link) => {
//   return new ethers.providers.JsonRpcProvider(link);
// };
// export const getContractWEB3 = (abi, address, provider) => {
//   try {
//     let contract = new provider.eth.Contract(abi, address);
//     return { contract };
//   } catch (error) {
//     console.log(error.message);
//     return { contract: null };
//   }
// };

// export const getProviderWEB3 = (link) => {
//   return new Web3(new Web3.providers.HttpProvider(link));
// };

// export const getContractWithSigner = async (library, account, address, abi) => {
//   let signer = library?.getSigner(account).connectUnchecked();
//   try {
//     let _contract;
//     _contract = new Contract(address, abi, signer);
//     return { ok: true, contract: _contract };
//   } catch (error) {
//     console.log(error);
//     return { ok: false, contract: null };
//   }
// };
// export const getContract = async (library, address, abi) => {
//   // console.log(library, address, abi);
//   try {
//     let _contract;
//     _contract = new Contract(address, abi, library);
//     console.log(_contract);
//     return { ok: true, contract: _contract };
//   } catch (error) {
//     console.log(error);
//     return { ok: false, contract: null };
//   }
// };

// export const getUserBalance = async (library, account) => {
//   try {
//     let balance = await library.eth.getBalance(account);
//     balance = formatFromWei(balance);

//     return Number(balance).toFixed(4);
//   } catch (error) {
//     console.log(error);
//     return 0;
//   }
// };
// export const getTokenDetails = async (library, tokenAddress) => {
//   const { ok, contract: tokenContract } = await getContract(
//     library,
//     tokenAddress,
//     tokenAbi
//   );
//   if (!ok) return null;
//   try {
//     const decimals = await tokenContract.decimals();
//     const symbol = await tokenContract.symbol();
//     return { decimals, symbol };
//   } catch (error) {
//     return null;
//   }
// };

// export const isValidAddress = (address) => {
//   try {
//     const result = ethers.utils.isAddress(address);
//     console.log(result);
//     return result;
//   } catch (error) {
//     return false;
//   }
// };
// export const approveToken = async (
//   library,
//   tokenAddress,
//   contractAddress,
//   account,
//   amount,
//   setLoading = () => {}
// ) => {
//   const { ok, contract: tokenContract } = await getContractWithSigner(
//     library,
//     account,
//     tokenAddress,
//     tokenAbi
//   );

//   if (!ok) {
//     setLoading(false);
//     toast.error("Something went wrong");
//     return { ok: false };
//   }
//   setLoading(true);

//   const toastId = toast.loading("Please Wait Transaction is processing");

//   let balance = await tokenContract.balanceOf(account);
//   let decimals = await tokenContract.decimals();
//   balance = formatFromWei2(balance, decimals);
//   console.log("BALANCE", balance);

//   if (+balance < +amount || +balance === 0) {
//     setLoading(false);
//     toast.error("You dont have sufficient balance.", { id: toastId });
//     return { ok: false };
//   }
//   // const allowance = await tokenContract.allowance(account, contractAddress);

//   // console.log(allowance, totalSupply);
//   const totalSupply = await tokenContract.totalSupply();

//   toast.loading("Please Allow our protocal to use your token", { id: toastId });
//   try {
//     const tx = await tokenContract.approve(contractAddress, totalSupply, {
//       from: account,
//     });

//     toast.loading(
//       "Please wait to get confirmation of the transaction from blockchain",
//       { id: toastId }
//     );
//     await tx.wait();

//     toast.success("Approve Transaction successfully completed!", {
//       id: toastId,
//     });
//     setLoading(false);
//     return { ok: true };
//   } catch (error) {
//     toast.error("Something went wrong!", { id: toastId });
//     setLoading(false);
//     return { ok: false };
//   }
// };
// export const createLock = async (
//   library,
//   address,
//   abi,
//   account,
//   token,
//   amount,
//   title,
//   date,
//   LP,
//   withdrawer,
//   setLoading = () => {}
// ) => {
//   const { ok, contract } = await getContractWithSigner(
//     library,
//     account,
//     address,
//     abi
//   );

//   if (!ok) {
//     setLoading(false);
//     toast.error("Something went wrong");
//     return { ok: false };
//   }
//   setLoading(true);

//   const toastId = toast.loading("Please Wait Transaction is processing");
//   const tokenDetails = await getTokenDetails(library, token);
//   console.log(tokenDetails);
//   // return { ok: false };
//   amount = formatToWei2(amount, tokenDetails.decimals);
//   console.log(amount);
//   try {
//     const tx = await contract.lockToken(
//       token,
//       amount,
//       title,
//       date,
//       LP,
//       account,
//       withdrawer
//     );

//     toast.loading(
//       "Please wait to get confirmation of the transaction from blockchain",
//       { id: toastId }
//     );
//     await tx.wait();

//     toast.success(" Transaction successfully completed!", {
//       id: toastId,
//     });
//     setLoading(false);
//     return { ok: true };
//   } catch (error) {
//     console.log(error);
//     toast.error("Something went wrong!", { id: toastId });
//     setLoading(false);
//     return { ok: false };
//   }
// };

// export const changePresaleFees = async (
//   library,
//   chainId,
//   account,
//   feeOnPoolCreation,
//   fee1,
//   fee2,
//   withdrawFee,
//   baseFee,
//   setLoading = () => {}
// ) => {
//   const address = LaunchPadAdd[chainId];
//   const { ok, contract } = await getContractWithSigner(
//     library,
//     account,
//     address,
//     LaunchPadABI
//   );
//   console.log(contract);

//   if (!ok) {
//     setLoading(false);
//     toast.error("Something went wrong");
//     return { ok: false };
//   }
//   setLoading(true);

//   const toastId = toast.loading("Please Wait Transaction is processing");
// //  feeOnPoolCreation = formatToWei2(feeOnPoolCreation);
// //  withdrawFee = formatToWei2(withdrawFee);
//   baseFee = formatToWei2(baseFee);
//   try {
//     const tx = await contract.changeFeeForPoolCreation(
//       formatToWei2(feeOnPoolCreation),
//       fee1,
//       fee2,
//       withdrawFee,
//       baseFee,
//       {
//         gasLimit: 500000,
//       }
//     );

//     toast.loading(
//       "Please wait to get confirmation of the transaction from blockchain",
//       { id: toastId }
//     );
//     await tx.wait();

//     toast.success(" Transaction successfully completed!", {
//       id: toastId,
//     });
//     setLoading(false);
//     return { ok: true };
//   } catch (error) {
//     console.log(error);
//     toast.error("Something went wrong!", { id: toastId });
//     setLoading(false);
//     return { ok: false };
//   }
// };
// export const changePresaleAdmin = async (
//   library,
//   chainId,
//   account,
//   receiver,
//   admin,
//   setLoading = () => {}
// ) => {
//   const address = LaunchPadAdd[chainId];
//   const { ok, contract } = await getContractWithSigner(
//     library,
//     account,
//     address,
//     LaunchPadABI
//   );
//   console.log(contract);

//   if (!ok) {
//     setLoading(false);
//     toast.error("Something went wrong");
//     return { ok: false };
//   }
//   setLoading(true);

//   const toastId = toast.loading("Please Wait Transaction is processing");

//   try {
//     const tx = await contract.changeAdmin(receiver, admin, {
//       gasLimit: 500000,
//     });

//     toast.loading(
//       "Please wait to get confirmation of the transaction from blockchain",
//       { id: toastId }
//     );
//     await tx.wait();

//     toast.success(" Transaction successfully completed!", {
//       id: toastId,
//     });
//     setLoading(false);
//     return { ok: true };
//   } catch (error) {
//     console.log(error);
//     toast.error("Something went wrong!", { id: toastId });
//     setLoading(false);
//     return { ok: false };
//   }
// };
// export const changeTokenFee = async (
//   library,
//   chainId,
//   account,
//   fee,
//   setLoading = () => {}
// ) => {
//   const address = tokenlauncherAdd[chainId];
//   const { ok, contract } = await getContractWithSigner(
//     library,
//     account,
//     address,
//     tokenLauncherAbi
//   );
//   console.log(contract);

//   if (!ok) {
//     setLoading(false);
//     toast.error("Something went wrong");
//     return { ok: false };
//   }
//   setLoading(true);

//   const toastId = toast.loading("Please Wait Transaction is processing");
//   fee = formatToWei(fee);
//   try {
//     const tx = await contract.setFee(fee, {
//       gasLimit: 500000,
//     });

//     toast.loading(
//       "Please wait to get confirmation of the transaction from blockchain",
//       { id: toastId }
//     );
//     await tx.wait();

//     toast.success(" Transaction successfully completed!", {
//       id: toastId,
//     });
//     setLoading(false);
//     return { ok: true };
//   } catch (error) {
//     console.log(error);
//     toast.error("Something went wrong!", { id: toastId });
//     setLoading(false);
//     return { ok: false };
//   }
// };
// export const changeTokenAdmin = async (
//   library,
//   chainId,
//   account,
//   admin,
//   feeReceiver,
//   setLoading = () => {}
// ) => {
//   const address = tokenlauncherAdd[chainId];
//   const { ok, contract } = await getContractWithSigner(
//     library,
//     account,
//     address,
//     tokenLauncherAbi
//   );
//   console.log(contract);

//   if (!ok) {
//     setLoading(false);
//     toast.error("Something went wrong");
//     return { ok: false };
//   }
//   setLoading(true);

//   const toastId = toast.loading("Please Wait Transaction is processing");

//   try {
//     const tx = await contract.changeAdmin(admin, feeReceiver, {
//       gasLimit: 500000,
//     });

//     toast.loading(
//       "Please wait to get confirmation of the transaction from blockchain",
//       { id: toastId }
//     );
//     await tx.wait();

//     toast.success(" Transaction successfully completed!", {
//       id: toastId,
//     });
//     setLoading(false);
//     return { ok: true };
//   } catch (error) {
//     console.log(error);
//     toast.error("Something went wrong!", { id: toastId });
//     setLoading(false);
//     return { ok: false };
//   }
// };
