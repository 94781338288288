import { useEffect } from "react";

// Component Import
import { Routes, Route } from "react-router-dom";
import Layout from "./components/common/layout/Layout";
import WebFont from "webfontloader";

// Pages Import
import 'react-toastify/dist/ReactToastify.css';

// Context Import
import { LayoutProvider } from "./contexts/LayoutContext";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound";
import { Toaster } from "react-hot-toast";

import StakeDetails from "./pages/StakeDetails/StakeDetails";
import CreatePresale from "./pages/CreatePresale/CreatePresale";

function App() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Russo One", "sans-serif"],
      },
    });
  }, []);


  
  

  return (
    <LayoutProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:params" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/createpresale" element={<CreatePresale />} />
          <Route path="/details" element={<StakeDetails />} />
          <Route path="/details/:contractAddress/:chainName" element={<StakeDetails />} />
        </Routes>
        <Toaster containerClassName="text-sm" />
      </Layout>
    </LayoutProvider>
  )
}

export default App
