import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import locker from "../../../../assets/images/header/locker.svg"  //"../images/header/locker.svg";
// Assets
import controller from "../../../../assets/images/header/controller.svg";
import logo from "../../../../assets/images/global/logo.svg";

// Data
import headerData from "../../../../assets/data/header";

// Context
import { useLayoutContext } from "../../../../contexts/LayoutContext";
import { useWeb3React } from "@web3-react/core";
import { LaunchPadABI, chainidSelected, launchPadAdd, rpc } from "../../../../config";
import Web3 from "web3";


const SideBar = () => {

  const [selected, setSelected] = useState();
  const {account,chainId} = useWeb3React()
  const chain = chainId ? chainId : chainidSelected;
  const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chain]))
  const contractR = new web3.eth.Contract(LaunchPadABI,launchPadAdd[chain])
  const [admin, setAdmin] = useState();
  
  
  useEffect(()=>{
    const abc = async ()=>{
      const _farm = await contractR.methods.admin().call()
      setAdmin(_farm)
    }

    abc()


   },[]) 


  const navigate = useNavigate()


  const [open, setOpen] = useState(true);

  const { active, setActive } = useLayoutContext();

  return (
    <div className={`${open ? "w-[17rem]" : "w-20"} pt-8 relative duration-300`} >
      <img
        src={controller}
        className={`absolute cursor-pointer -right-3 top-20 w-7 h-7 ${!open && "rotate-180"}`}
        onClick={() => setOpen(!open)}
      />
      
      <div className={`flex gap-x-4 items-center px-5`}>
        <img
          onClick={()=>{navigate("/")}}
          src={logo}
          className={`cursor-pointer duration-500 ${open && "rotate-[360deg]"}`}
        />
        <h1 className={`text-white uppercase origin-left text-lg font-bold duration-800 ${!open && "scale-0"}`}>
          Safeone chain
        </h1>
      </div>

      <div className="h-[1px] bg-gray-500 my-6" />
    
      <span className={`${!open && 'hidden'} text-gray-200 px-5 text-xs uppercase`}>Main</span>
      <ul className="px-5">
        {headerData.map((header, index) => (
          <Link
            key={index}
            className={`flex rounded-md p-2 cursor-pointer hover:bg-white/20 text-gray-300 text-sm items-center gap-x-4 ${active === index && "bg-[#2D2F39]"} mt-2`}
            to={header.link}
            target={header.link.includes("http") ? "_blank" : "_self"}
            onClick={() => setActive(index)}
          >
            <img src={header.icon} />
            <span className={`${!open && "hidden"} origin-left duration-800`}>
              {header.title}
            </span>
          </Link>
        ))}
        {account && admin==account ? <Link

            className={`flex rounded-md p-2 cursor-pointer hover:bg-white/20 text-gray-300 text-sm items-center gap-x-4 ${active === headerData.length+1 && "bg-[#2D2F39]"} mt-2`}
            to={"/createpresale"}

            onClick={() => setActive(headerData.length+1)}
          >
            <img src={locker} />
            <span className={`${!open && "hidden"} origin-left duration-800`}>
              Create Presale
            </span>
          </Link> : null }
          
      </ul>

      <div className="h-[1px] bg-gray-500 my-6" />
    </div>
  );
};

export default SideBar;