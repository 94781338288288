import React, { useRef } from "react";
import { useContractWrite, useWaitForTransaction, useAccount, useNetwork, } from "wagmi";
import { toast } from "react-hot-toast";
import { chainName } from "../../config";

export default function FarmAction({ args, value, con, abi, fun, title, setOpen, setAmount, chainId, success, fail }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork()
    const hasBeenCalledRef = useRef(false);

    // console.log("actions", args, matches, con, abi, fun,title,bulkClaim);
    const { write, data } = useContractWrite({
        address: con,
        abi: abi,
        functionName: fun,
        args,
        value: value,
        overrides: {
            from: address,
            //   value: parseEther('0.01'),
        },

        onError(error) {
            var str = JSON.stringify(error)
            var obj = JSON.parse(str)
            var reason = obj.cause.reason
            reason ? toast.error(`${reason}`) : toast.error(`${obj?.details}`)
            setOpen(false)
        }
    })


    const stakeWait = useWaitForTransaction({ hash: data?.hash, })

    if (stakeWait.isSuccess) {
        if (!hasBeenCalledRef.current) {
            hasBeenCalledRef.current = true;
            setOpen(false)
            setAmount(0)
            success();
        }
    } else if (stakeWait.isError) {
        if (!hasBeenCalledRef.current) {
            hasBeenCalledRef.current = true;
            setOpen(false)
            fail();
        }
    }

    return (
        <span
            style={{ cursor: "pointer" }}
            className="bg-[#484959] rounded py-1 px-4 text-sm font-semibold"
            disabled={!write}
            onClick={() => {
                if (isConnected) {
                    if (chainId === chain.id) {
                        hasBeenCalledRef.current = false;
                        setOpen(true)
                        write()
                    } else {
                        toast.error(`Please connect to ${chainName[chainId]} network`)
                    }
                } else {
                    toast.error("Please connect wallet")
                }
            }}
        >
            {title}
        </span>




    );
}
