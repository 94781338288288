import { Contract } from "ethers";
import DetailSection from "../../components/StakeDetails/DetailSection";
import FarmStats from "../../components/StakeDetails/FarmStats";
import ProjectDetails from "../../components/StakeDetails/ProjectDetails";
import ReferralLink from "../../components/StakeDetails/ReferralLink";
import TokenDetails from "../../components/StakeDetails/TokenDetails";
import VideoSection from "../../components/StakeDetails/VideoSection";

import "../../styles/stakeDetails.css";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IERC20,
  chainTicker,
  coinGeckoTicker,
  getChainId,
  presaleAbi,
  rpc,
} from "../../config";
import Web3 from "web3";
import axios from "axios";
import { formatEther } from "ethers/lib/utils";
import ResponsiveDialog from "../../spinner";
import AdminPanel from "../../components/StakeDetails/AdminPanel";
import { chainsImg } from "../../components/common/Header/NavBar/chaindata";
import { useAccount } from "wagmi";
import { routerAbi } from "../../constants/routerAbi";
import { factoryAbi } from "../../constants/factoryAbi";
import { formatUnits } from "viem";
import moment from "moment/moment";

const styles = {
  padding: "p-2 pt-8 lg:px-40 xl:px-44 2xl:px-50 text-white",
  background: "bg-[#1E1F35CC]/80",
};

const { padding, background } = styles;

// export const getContract = (library, account, add, abi) => {
//   const signer = library?.getSigner(account).connectUnchecked();
//   var contract = new Contract(add, abi, signer);
//   return contract;
// };

const StakeDetails = () => {
  const { contractAddress, chainName } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { address } = useAccount()

  const chainId = getChainId[chainName] ? getChainId[chainName] : 56;

  const web3 = new Web3(new Web3.providers.HttpProvider(rpc[chainId]));
  const contractR = new web3.eth.Contract(presaleAbi, contractAddress);

  const [data, setData] = useState();
  const [dollarPrice, setDollarPrice] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [userBNBBalance, setUserBNBBalance] = useState(0);
  const [userLPBalance, setUserLPBalance] = useState(0);
  const [userPaid, setUserPaid] = useState(0);
  const [userPending, setUserPending] = useState(0);
  const [openSpinner, setOpenspinner] = useState(false);
  const [APR, setAPR] = useState(0);
  const [admin, setAdmin] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [userStakedbalance, setUserStakedBalance] = useState(0);
  const [referralBonus, setReferralBonus] = useState(0)
  const [userReferralIncome, setUserReferralIncome] = useState(0)
  const [emergencyTax, setEmergencyTax] = useState(0)
  const [contractBalance, setContractBalance] = useState(0)
  const [totalStaked, setTotalStaked] = useState(0)
  const [finalized, setFinalized] = useState(false);
  const [shifted, setShifted] = useState(false);
  const [lpValueInBnb, setLpValueInBnb] = useState(0);
  const [perTokenPriceBnb, setPerTokenPriceBnb] = useState(0);
  const [tokenTotalSupply, setTokenTotalSupply] = useState(0);
  const [poolStartTime, setPoolStartTime] = useState(0);
  const [poolEndTime, setPoolEndTime] = useState(0);





  useEffect(() => {
    abc();
  }, [openSpinner]);


  const abc = async () => {
    try {
      const _data = await contractR.methods.getSelfInfo().call();
      // console.log("_data", _data)
      setData(_data);

      const _poolStartTime = await contractR.methods.poolStartTime().call();
      setPoolStartTime(_poolStartTime);
      const _poolEndTime = await contractR.methods.poolEndTime().call();
      setPoolEndTime(_poolEndTime);


      const TokencontractR = new web3.eth.Contract(IERC20, _data[3]);
      const tokenDecimals = await TokencontractR.methods.decimals().call();
      const _totalSupply = await TokencontractR.methods.totalSupply().call();
      setTokenTotalSupply(+formatUnits(_totalSupply.toString(), tokenDecimals.toString()))

      const routerR = new web3.eth.Contract(routerAbi, _data[18]);
      const factoryAddress = await routerR.methods.factory().call();
      const WethAddress = await routerR.methods.WETH().call();
      const wethContract = new web3.eth.Contract(IERC20, WethAddress);

      // console.log("factoryAddress", factoryAddress, WethAddress)
      const factoryR = new web3.eth.Contract(factoryAbi, factoryAddress);
      const pairAddress = await factoryR.methods.getPair(_data[3], WethAddress).call();
      const pairContract = new web3.eth.Contract(IERC20, pairAddress);

      const reserve0 = await TokencontractR.methods.balanceOf(pairAddress).call();
      const reserve1 = await wethContract.methods.balanceOf(pairAddress).call();

      const totalSupply = await pairContract.methods.totalSupply().call();

      setLpValueInBnb(+reserve1 / +totalSupply)

      // const _totalTokensLocked =  await contractR.methods.owner().call();
      // const _totalLpLocked = await contractR.methods.owner().call();



      const pariTokenBlance = +formatUnits(
        reserve0.toString(),
        tokenDecimals.toString()
      );
      const pairBnbBlance = +formatEther(reserve1.toString());

      const price = pairBnbBlance / pariTokenBlance;

      setPerTokenPriceBnb(price);


      const _own = await contractR.methods.owner().call();

      setAdmin(_own);

      const _can = await contractR.methods.poolCancelled().call();

      setCancelled(_can);

      const _apr = await contractR.methods.rewardRate().call();

      setAPR(_apr);

      const _ET = await contractR.methods.emergencyFee().call();

      setEmergencyTax(_ET);


      const _RB = await contractR.methods.refferralPercentage().call();

      setReferralBonus(_RB);

      const _TS = await contractR.methods._totalStaked().call();

      setTotalStaked(_TS);

      const _fin = await contractR.methods.poolFinalized().call();

      setFinalized(_fin);

      const _shif = await contractR.methods.poolShifted().call();

      setShifted(_shif)

      const _CB = await contractR.methods.TokensForReward().call();

      setContractBalance(_CB);

    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPrice();
  }, []);

  const getPrice = async () => {
    try {
      const ethPrice = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coinGeckoTicker[chainId]}&vs_currencies=usd`
      );

      setDollarPrice(ethPrice.data[coinGeckoTicker[chainId]].usd);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (address) {
      getUserInfo();
    }
  }, [address, openSpinner]);

  const getUserInfo = async () => {
    try {
      const _uBal = await contractR.methods
        .userPresaleTBalance(address)
        .call();
      setUserBalance(_uBal);

      const _uBBal = await contractR.methods
        .userPresaleMBalance(address)
        .call();
      setUserBNBBalance(_uBBal);

      const _uLBal = await contractR.methods
        .userPresaleLBalance(address)
        .call();
      setUserLPBalance(_uLBal);

      const _uearned = await contractR.methods.earned(address).call();
      setUserPending(_uearned);

      const _upaid = await contractR.methods.userPaidRewards(address).call();
      setUserPaid(_upaid);

      const _preStake = await contractR.methods.userStakedBalance(address).call();
      setUserStakedBalance(_preStake);

      const _refIncome = await contractR.methods.ReferralIncome(address).call();
      setUserReferralIncome(_refIncome);
    } catch (error) {
      console.log("error in getting user info", error);
    }
  }

  const now = new Date().getTime() / 1000;
  const status = (data && finalized) ? shifted ? "SHIFTED" : "COMPLETED"
    : cancelled ? "CANCELLED" :
      "LIVE";

  // console.log("data[2]", data && data[2])

  const progress = data
    ? (Number(formatEther(data[5])) / Number(formatEther(data[0]))) * 100
    : 0;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function convertToDate(str) {
    const _day = new Date(str * 1000).getDate();
    const formatedDays = _day / 10 > 1 ? `${_day}` : `0${_day}`;
    const _month = new Date(str * 1000).getMonth();
    const formatedMonths = _month / 10 > 1 ? `${_month}` : `0${_month}`;
    const _year = new Date(str * 1000).getFullYear();
    const _hours = new Date(str * 1000).getHours();
    const formatedHours = _hours / 10 > 1 ? `${_hours}` : `0${_hours}`;
    const _minutes = new Date(str * 1000).getMinutes();
    const formatedMinutes = _minutes / 10 > 1 ? `${_minutes}` : `0${_minutes}`;

    return `${months[_month]} ${formatedDays},${_year}  ${formatedHours}:${formatedMinutes}`;
  }

  const EndDate = Number(poolEndTime)
  // data &&
  // data[7] * 1000 + data[14] * (60 * 60 * 24 * 1000);
  const nowdate = new Date().getTime();

  const _days = Math.abs(+poolStartTime - +poolEndTime) / 60 / 60 / 24;
  const _remaingDays =
    Math.abs(+poolStartTime - +moment().unix()) / 60 / 60 / 24;

  const remainingDays = (_days > _remaingDays) ? parseInt(_days - _remaingDays) : 0;



  const youtubeArray = data && data[16].split("watch?v=");
  // const youtubeVideo =
  //   data && `https://www.youtube.com/embed/${youtubeArray[1]}`;


  return (
    <>
      <main className={`${padding}`}>
        <section>
          <DetailSection
            logo={data && data[12]}
            name={data && data[13]}
            status={data && status}
            icon={chainsImg[chainId].icon}
            badges={data && data[10]}
            data={data && data}
          />
        </section>

        <section className={`${background} mt-4 py-2 px-6 md:py-6 md:px-12`}>
          <TokenDetails
            symbol={chainTicker[chainId]}
            liquidity={data && data[11]}
            softCap={data && formatEther(data[0])}
            marketCap={
              data &&
              Number(tokenTotalSupply * (perTokenPriceBnb * dollarPrice)).toFixed(2)
              //  (Number(formatEther(data[0])) * dollarPrice).toFixed(5)
            }
            price={data && Number(formatEther(data[2]))}
            tsymbol={data && data[15]}
          />
        </section>

        <section className="mt-4">
          <VideoSection data={data && data} />
        </section>

        <section className={`${background} mt-4 p-4`}>
          <ProjectDetails
            desc={data && data[17] ? data[17] : "Some description"}
          />
        </section>

        <section className={`${background} mt-4 p-4`}>
          <FarmStats
            emergencyTax={data && +emergencyTax/100}
            tsymbol={data && data[15]}
            poolStart={
              data && Number(poolStartTime) > 0
                ? convertToDate(Number(poolStartTime))
                : "00:00:00  00:00"
            }
            poolEnd={data && Number(poolEndTime) > 0
              ? convertToDate(Number(poolEndTime))
              // convertToDate(Number(data[7]) + Number(data[14]) * (60 * 60 * 24))
              : "00:00:00  00:00"}
            duration={data && data[14]}
            remaining={remainingDays}
            logo={data && data[12]}
            symbol={chainTicker[chainId]}
            userStakedBalance={Number(formatEther(userStakedbalance)).toFixed(4)}
            userBNBBalance={Number(formatEther(userBNBBalance)).toFixed(4)}
            userdollarBalance={Number(
              Number(formatEther(userBNBBalance)) * dollarPrice
            ).toFixed(2)}
            userTokenbalance={Number(formatEther(userBalance)).toFixed(4)}
            usertokenBalancedollar={
              data &&
              (
                Number(formatEther(userBalance)) *
                perTokenPriceBnb *
                dollarPrice
              ).toFixed(2)
            }
            userLPbalance={Number(formatEther(userLPBalance)).toFixed(4)}
            userLPbalanceDollar={
              data &&
              Number(
                Number(formatEther(userLPBalance)) *
                lpValueInBnb *
                dollarPrice
              ).toFixed(2)
            }

            pendingReward={Number(formatEther(userPending)).toFixed(4)}
            pendingRewardDollar={
              data &&
              Number(
                Number(formatEther(userPending)) *
                perTokenPriceBnb *
                dollarPrice
              ).toFixed(2)
            }
            paidrewards={Number(formatEther(userPaid)).toFixed(8)}
            paidRewarddollar={
              data &&
              Number(
                Number(formatEther(userPaid)) *
                perTokenPriceBnb *
                dollarPrice
              ).toFixed(2)
            }
            APR={data && Number(
              formatEther(APR)
              * 365 * (60 * 60 * 24)
              /
              formatEther(data[19])
            ).toFixed(3)
            }
            TVL={
              data &&
              Number((+formatEther(data.tokensSold) * perTokenPriceBnb * dollarPrice) + (+formatEther(data.LPTokens) * lpValueInBnb * dollarPrice)).toFixed(2)
            }
            data={data && data}
            openSpinner={openSpinner}
            setOpenspinner={setOpenspinner}
            refAddress={searchParams.get("ref") === null && searchParams.get("ref")?.length != 42 ? "0x0000000000000000000000000000000000000000" : searchParams.get("ref")}
            params={contractAddress}
            chainId={chainId}
            admin={admin}
            cancelled={cancelled}
            finalized={finalized}
            dollarPrice={dollarPrice}
            progress={progress}
            status={status}
            marketCap={
              data &&
              Number(tokenTotalSupply * (perTokenPriceBnb * dollarPrice)).toFixed(2)
              //  (Number(formatEther(data[0])) * dollarPrice).toFixed(5)
            }
          />
        </section>

        {address && (
          <section className={`${background} mt-4 p-2 lg:py-4`}>
            <ReferralLink
              referralBonus={referralBonus}
              userReferralIncome={userReferralIncome}
              chainName={chainName}
              contractAddress={contractAddress}
            />
          </section>
        )}

        {address === admin && data ?
          <section className={`${background} mt-4 p-2 lg:py-4`}>
            <AdminPanel
              openSpinner={openSpinner}
              setOpenspinner={setOpenspinner}
              params={contractAddress}
              chainId={chainId}
              data={data && data}
              contractBalance={Number(formatEther(contractBalance)).toFixed(2)}
              totalStaked={formatEther(totalStaked)}
              APR={data && (APR / data[4] * 365 * (60 * 60 * 24) * 100).toFixed(3)}
            // APRFactor={data && Number(formatEther(data[0])) * Number(data[4])*100000/(100*365) }
            />
          </section>
          : null
        }
      </main>
      <ResponsiveDialog open={openSpinner}></ResponsiveDialog>
    </>
  );
};

export default StakeDetails;
